import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type {
  ICompanyDocumentFileEntityArgs,
  ICompanyDocumentFileEntry,
  ICompanyDocumentFileEntryArgs,
  IDeleteCompanyDocumentFileArgs,
  IPostCompanyDocumentFileArgs,
} from './companyDocumentFiles.api.types';

export const getCompanyDocumentFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  ICompanyDocumentFileEntityArgs,
  BaseQueryFn,
  string,
  ICompanyDocumentFileEntry
> =>
  build.query<ICompanyDocumentFileEntry, ICompanyDocumentFileEntityArgs>({
    query: (queryArg: ICompanyDocumentFileEntityArgs) => ({
      url: `api/company_document_files/${queryArg.id}`,
    }),
  });

export const postCompanyDocumentFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  ICompanyDocumentFileEntryArgs,
  BaseQueryFn,
  string,
  ICompanyDocumentFileEntry
> =>
  build.mutation<ICompanyDocumentFileEntry, ICompanyDocumentFileEntryArgs>({
    query: (queryArg) => ({
      url: `api/company_document_files`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const postCompanyDocumentFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostCompanyDocumentFileArgs,
  BaseQueryFn,
  string,
  ICompanyDocumentFileEntry
> =>
  build.mutation<ICompanyDocumentFileEntry, IPostCompanyDocumentFileArgs>({
    query: (queryArg) => ({
      url: `api/company_document_files/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });

export const deleteCompanyDocumentFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteCompanyDocumentFileArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteCompanyDocumentFileArgs>({
    query: (queryArg) => ({
      url: `api/company_document_files/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
