import Joi, { AnySchema, ErrorReport } from 'joi';
import { TFunction } from 'i18next';

export const isValidBic = (bic: string): boolean => {
  /** BIC format:
   * Bank Code as 4 alphabetic characters,
   * Country Code as 2 alphabetic characters,
   * Location Code as 2 alphanumeric characters,
   * Branch Code as 3 alphanumeric characters (optional)
   */
  const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

  return bicRegex.test(bic);
};

const getBicValidation =
  (fieldRequired = false) =>
  (
    value: string,
    helpers: Joi.CustomHelpers<unknown>
  ): string | ErrorReport => {
    const invalidValueError = helpers.error('any.valueInvalid');
    const fieldRequiredError = helpers.error('any.required');
    if (!value) {
      return fieldRequired ? fieldRequiredError : value;
    }
    if (!isValidBic(value)) {
      return invalidValueError;
    }

    return value;
  };

export const bicValidationStrategy = (
  isFieldRequired: boolean,
  t: TFunction
): AnySchema =>
  Joi.custom(getBicValidation(isFieldRequired)).messages({
    'any.valueInvalid': t('form:validation.bic'),
    'any.required': t('modal.validation.fieldRequired'),
  });
