import { TFunction } from 'i18next';

import { ISelectValue } from 'interfaces/Data/SelectValue.interface';
import { TDocumentTemplate } from 'interfaces/DocumentTemplate.interface';

export const getDocumentTemplateTypesOptions = (
  t: TFunction
): ISelectValue<TDocumentTemplate>[] => [
  {
    label: t('modal.labels.custom'),
    value: 'custom',
  },
  {
    label: t('modal.labels.workContract'),
    value: 'work-contract',
  },
  {
    label: t('modal.labels.generalContract'),
    value: 'general-contract',
  },
  {
    label: t('modal.labels.uploadable'),
    value: 'uploadable',
  },
];
