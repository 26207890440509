import { createContext, FC, useContext, useEffect, useState } from 'react';
import { SpaceApi, loadSpace, InitOptions } from '@usersnap/browser';

import { getUserSnapRoleFromUserRolesArray } from 'helpers/roles/roles.helper';
import { useCurrentUser } from 'hooks/useCurrentUser';

export const UsersnapContext = createContext<SpaceApi | null>(null);

export const UsersnapProvider: FC = () => {
  const [usersnapApi, setUsersnapApi] = useState<SpaceApi | null>(null);
  const usersnapGlobalApiKey = import.meta.env.VITE_USERSNAP_GLOBAL_API_KEY;

  const user = useCurrentUser();

  useEffect(() => {
    // only load usersnap in production and if the global api key is set
    if (import.meta.env.PROD && usersnapGlobalApiKey) {
      loadSpace(usersnapGlobalApiKey).then(async (api: SpaceApi) => {
        // if the user is not logged in, we need to destroy the usersnap session
        // and reinitialize it with the anonymous user role
        await api.destroy();
        const userObject: InitOptions['user'] = user.email
          ? {
              userId: user.email,
              email: user.email,
              role: getUserSnapRoleFromUserRolesArray(user.roles),
            }
          : {
              role: 'ANONYMOUS',
            };

        api.init({
          collectGeoLocation: 'none',
          useLocalStorage: false,
          useSystemFonts: true,
          user: userObject,
          custom: {
            environment: 'HEROLOGIN_' + import.meta.env.MODE,
          },
        });
        setUsersnapApi(api);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersnapGlobalApiKey, user.email]);

  return <UsersnapContext.Provider value={usersnapApi} />;
};

export const useUsersnapApi = () => {
  return useContext(UsersnapContext);
};
