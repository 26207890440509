import { TFunction } from 'i18next';
import { uniqBy } from 'lodash';
import moment from 'moment';

import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IApplication } from 'interfaces/Applications.interface';
import type { ITimelineItem } from 'components/Timeline/interfaces/timeline.interface';
import type { IContact } from 'interfaces/Contact.interface';
import type { IContactDocument } from 'interfaces/ContactDocument.interface';
import type { IDocumentTemplate } from 'interfaces/DocumentTemplate.interface';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type { IProjectShift } from 'interfaces/ProjectShift.interface';
import type { ICurrencyFormatOptions } from 'hooks/numbers/useCurrency.hook';
import type { IProject } from 'interfaces/Project.interface';
import type { ICompany } from 'interfaces/Company.interface';
import { getDuringStringHelper } from 'helpers/dateTime/getDuringString.helper';
import { displayDurationAsTimeFromStartEnd } from 'helpers/dateTime/displayDurationAsTime.helper';
import { getSummedItemsFromShiftsWithGroupByKey } from 'components/Timeline/helper/shiftTimeline.helper';
import { checkEmbedIsContact } from 'helpers/embed/embedTypeCheck.helper';
import { dateService } from 'services/date/date.service';
import { getFullAddress } from 'helpers/address/address.helper';
import { getContractSettingsOptions } from 'helpers/contractOptions/getContractOptions.helper';
import { getDocumentTemplateTypesOptions } from 'helpers/documentTemplates/getDocumentTemplateTypesOptions.helper';
import { getPaymentTypeOptions } from 'helpers/currency/getPaymentTypeOptions.helper';
import {
  getDuration,
  getDurationAsHoursString,
} from 'helpers/dateTime/getDuration.helper';

interface IResolveMarkersArgs {
  input: string;
  documentTemplate: IDocumentTemplate;
  currencyFormatter: (
    value: number,
    options?: ICurrencyFormatOptions | undefined
  ) => string;
  document?: Pick<
    IContactDocument,
    'start' | 'end' | 'description' | 'probationEnd'
  >;
  contact?: IContact | IApplication;
  mission?: IProjectMission;
  getShiftsCallback?: (filters: ISimpleFilter[]) => Promise<IProjectShift[]>;
  project?: IProject;
  company?: ICompany;
  t: TFunction;
}

const resolveMarkers = async ({
  input,
  documentTemplate,
  mission,
  contact,
  document,
  getShiftsCallback = () => Promise.resolve([]),
  company,
  project,
  currencyFormatter,
  t,
}: IResolveMarkersArgs): Promise<string> => {
  const regexp = /{{\s\w*\s}}/g; // find all {{ markers }} in string
  const matches = input.match(regexp);

  const isContact = checkEmbedIsContact(contact);

  let html = input;

  const getContactName = (
    contactValue?: Pick<IContact, 'firstName' | 'lastName'>
  ): string => {
    if (!contactValue) {
      return '';
    }
    return [contactValue?.firstName, contactValue?.lastName].join(' ');
  };

  const getShiftContactName = (shift?: IProjectShift): string => {
    return getContactName(shift?.contact);
  };

  const getConfirmedShifts = async (
    isOnlyCurrentContact: boolean
  ): Promise<IProjectShift[]> => {
    const filters: ISimpleFilter[] = [
      { key: 'status', operator: 'AND', value: 'confirmed' },
    ];
    if (isOnlyCurrentContact && contact) {
      filters.push({
        key: 'contact',
        operator: 'AND',
        value: contact?.id,
      });
    }
    const shifts = await getShiftsCallback(filters);

    return shifts;
  };

  const getShiftPayment = (shift?: IProjectShift): string => {
    const shiftPayment = shift?.payment;
    const shiftPaymentType = shift?.paymentType;

    const shiftPaymentTypeLabel =
      getPaymentTypeOptions(t).find(
        (option) => option.value === shiftPaymentType
      )?.label || shiftPaymentType;

    if (shiftPayment) {
      return `${currencyFormatter(shiftPayment, {
        displayCurrency: true,
        divideBy: 100,
      })} ${shiftPaymentTypeLabel || ''}`;
    } else {
      return '';
    }
  };

  /**
   * Get shifts by period
   * @param isConfirmed if true, only confirmed shifts are returned
   * @param start used for filtering shifts by period
   * @param end used for filtering shifts by period
   */
  const getShiftsByPeriod = async (
    isConfirmed: boolean,
    start?: string,
    end?: string
  ): Promise<IProjectShift[]> => {
    if (!start || !end) {
      return [];
    }
    const documentStart = moment(start);
    const documentEnd = moment(end).endOf('day');
    const duringFilter: ISimpleFilter = {
      key: 'during',
      operator: 'AND',
      value: getDuringStringHelper(
        documentStart.toDate(),
        documentEnd.toDate()
      ),
    };

    const filters: ISimpleFilter[] = [duringFilter];

    // if isConfirmed is true, add confirmed filter
    if (isConfirmed) {
      filters.push({ key: 'status', operator: 'AND', value: 'confirmed' });
    }

    const projectShifts = await getShiftsCallback(filters);

    return projectShifts;
  };

  const getConfirmedShiftMarkup = (shift: IProjectShift): string => {
    return [
      `<p>${getShiftContactName(shift)}</p>`,
      `<p>${mission?.title || ''} ${shift.position}</p>`,
      `<p>${dateService(shift.start).format(
        'DD.MM.YYYY HH:mm'
      )} - ${dateService(shift.end).format(
        'DD.MM.YYYY HH:mm'
      )} - ${t('duration.label')} ${displayDurationAsTimeFromStartEnd({ start: shift.start, end: shift.end, t, displayPlusSign: false, addSpaceBeforeUnit: true })}</p>`,
    ].join('');
  };

  const getSummedShiftMarkup = (
    shift: ITimelineItem<IProjectShift[]>
  ): string => {
    const shiftData = shift.item[0];
    return `<p>${shiftData.position} ${dateService(shiftData.start).format(
      'DD.MM.YYYY HH:mm'
    )} - ${dateService(shiftData.end).format(
      'DD.MM.YYYY HH:mm'
    )} - ${t('duration.label')} ${displayDurationAsTimeFromStartEnd({ start: shiftData.start, end: shiftData.end, t, displayPlusSign: false, addSpaceBeforeUnit: true })} - ${t('shiftsAmount.count', { count: shift.item.length })}</p>`;
  };

  const getSummedShiftsCollectionMarkup = (
    projectShifts: IProjectShift[]
  ): string => {
    const summedShifts = getSummedItemsFromShiftsWithGroupByKey(
      projectShifts,
      'position'
    );
    return summedShifts
      .map((summedShift) => getSummedShiftMarkup(summedShift))
      .join('');
  };

  if (matches) {
    for (const marker of matches) {
      let value: string | undefined | null = '';
      switch (marker) {
        case '{{ current_date }}': {
          value = dateService().format('DD.MM.YYYY');
          break;
        }
        /**
         * contact markers
         */
        case '{{ salutation }}': {
          switch (contact?.gender) {
            case 'd':
              value = t('salutation.diverse');
              break;
            case 'f':
              value = t('salutation.female');
              break;
            case 'm':
              value = t('salutation.male');
              break;
            case 'x':
            default:
              value = t('salutation.general');
              break;
          }
          break;
        }
        case '{{ title }}': {
          value = contact?.title;
          break;
        }
        case '{{ first_name }}': {
          value = contact?.firstName;
          break;
        }
        case '{{ last_name }}': {
          value = contact?.lastName;
          break;
        }
        case '{{ name }}': {
          value = contact?.firstName + ' ' + contact?.lastName;
          break;
        }
        case '{{ date_of_birth }}':
          value = contact?.dateOfBirth
            ? dateService(contact.dateOfBirth).format()
            : '';
          break;
        case '{{ email }}': {
          value = contact?.email;
          break;
        }
        case '{{ street }}': {
          value = contact?.addressStreet;
          break;
        }
        case '{{ zip_code }}': {
          value = contact?.addressZip;
          break;
        }
        case '{{ city }}': {
          value = contact?.addressCity;
          break;
        }
        case '{{ phone_number }}': {
          value = contact?.phone;
          break;
        }
        case '{{ contract_type }}': {
          value = isContact
            ? getContractSettingsOptions(t).find(
                (option) => option.value === contact.contractType
              )?.label || contact.contractType
            : '';
          break;
        }
        /**
         * document related markers
         */
        case '{{ document_title }}': {
          value = documentTemplate.title;
          break;
        }
        case '{{ document_start }}': {
          value = document?.start ? dateService(document.start).format() : '';
          break;
        }
        case '{{ probation_end }}': {
          value = document?.probationEnd
            ? dateService(document.probationEnd).format()
            : '';
          break;
        }
        case '{{ document_end }}':
        case '{{ document_end_date }}': {
          value = document?.end ? dateService(document.end).format() : '';
          break;
        }
        /**
         * document template related markers
         */
        case '{{ document_type }}': {
          value =
            getDocumentTemplateTypesOptions(t).find(
              (option) => option.value === documentTemplate.type
            )?.label || documentTemplate.type;
          break;
        }
        case '{{ document_template }}': {
          value = documentTemplate.title;
          break;
        }
        case '{{ document_description }}': {
          value = document?.description;
          break;
        }
        /**
         * company related markers
         */
        case '{{ company_name }}': {
          value = company?.name;
          break;
        }
        case '{{ company_street }}': {
          value = company?.addressStreet;
          break;
        }
        case '{{ company_zip }}': {
          value = company?.addressZip;
          break;
        }
        case '{{ company_city }}': {
          value = company?.addressCity;
          break;
        }
        /**
         * project related markers
         */
        case '{{ project_leader }}': {
          value = getContactName(project?.leader);
          break;
        }
        case '{{ project_total_shifts_count }}': {
          value = project?.totalShiftsCount.toString();
          break;
        }
        case '{{ project_number }}': {
          value = project?.projectNumber;
          break;
        }
        case '{{ project_title }}': {
          value = project?.title;
          break;
        }
        case '{{ project_start }}': {
          value = project?.start
            ? dateService(project?.start).format('DD.MM.YYYY')
            : '';
          break;
        }
        case '{{ project_end }}': {
          value = project?.end
            ? dateService(project?.end).format('DD.MM.YYYY')
            : '';
          break;
        }
        /**
         * mission related markers
         */
        case '{{ mission_total_shifts_count }}': {
          value = mission?.totalShiftsCount.toString();
          break;
        }
        case '{{ address }}': {
          value = getFullAddress(mission);
          break;
        }
        case '{{ mission }}': {
          value = mission?.title;
          break;
        }
        case '{{ mission_location }}': {
          value = getFullAddress(mission);
          break;
        }
        case '{{ mission_leader }}': {
          value = `${mission?.leader?.firstName || ''} ${
            mission?.leader?.lastName || ''
          }`;
          break;
        }
        case '{{ mission_start }}': {
          value = mission?.start
            ? dateService(mission?.start).format('DD.MM.YYYY')
            : '';
          break;
        }
        case '{{ mission_end }}': {
          value = mission?.end
            ? dateService(mission.end).format('DD.MM.YYYY')
            : '';
          break;
        }
        case '{{ mission_duration }}': {
          if (mission?.start && mission?.end) {
            value = `${getDurationAsHoursString(
              getDuration(mission?.start, mission?.end, 0, false)
            )} ${t('hourShort')}`;
          } else {
            value = '';
          }
          break;
        }
        case '{{ mission_order_number }}': {
          value = mission?.orderNumber;
          break;
        }
        /**
         * mission confirmed jobs related markers
         * only shows confirmed jobs of current contact in mission
         */
        case '{{ mission_confirmed_jobs }}':
        case '{{ mission_confirmed_jobs_incl_payment }}': {
          const includePayment =
            marker === '{{ mission_confirmed_jobs_incl_payment }}';

          const currentContactConfirmedShifts = await getConfirmedShifts(true);

          value = currentContactConfirmedShifts
            .map((confirmedShift) => {
              const payment = getShiftPayment(confirmedShift);
              return `<p>${mission?.title || ''} ${confirmedShift.position} ${dateService(
                confirmedShift.start
              ).format('DD.MM.YYYY HH:mm')} - ${dateService(
                confirmedShift.end
              ).format(
                'DD.MM.YYYY HH:mm'
              )}${includePayment ? ` ${payment}` : ''},</p>`;
            })
            .join('');
          break;
        }
        /**
         * confirmed job related markers
         */
        case '{{ contacts_confirmed_jobs_in_period }}': {
          if (!document?.start || !document?.end) {
            value = '';
            break;
          }
          const shiftsInPeriod = await getShiftsByPeriod(
            true,
            document.start,
            document.end
          );
          value = shiftsInPeriod
            .map((shift) => {
              return getConfirmedShiftMarkup(shift);
            })
            .join('');
          break;
        }
        case '{{ contacts_confirmed_jobs }}': {
          const confirmedShifts = await getConfirmedShifts(false);
          value = confirmedShifts
            .map((shift) => {
              return getConfirmedShiftMarkup(shift);
            })
            .join('<br/>');
          break;
        }
        case '{{ contacts_names_confirmed_jobs }}': {
          const confirmedShifts = await getConfirmedShifts(false);
          const uniqueContacts = uniqBy(
            confirmedShifts
              .filter((shift) => !!shift.contact)
              .map((shift) => {
                return shift.contact;
              }),
            'id'
          );
          value = uniqueContacts
            .map((shiftContact) => {
              return `<p>${getContactName(shiftContact)}</p>`;
            })
            .join('<br/>');
          break;
        }
        case '{{ amount_unique_contacts }}': {
          const confirmedShifts = await getConfirmedShifts(false);
          const uniqueContacts = uniqBy(
            confirmedShifts
              .filter((shift) => !!shift.contact)
              .map((shift) => {
                return shift.contact;
              }),
            'id'
          );
          value = uniqueContacts.length.toString();
          break;
        }
        /**
         * payment of first confirmed job of current contact
         */
        case '{{ job_payment }}': {
          const confirmedShifts = await getConfirmedShifts(true);
          value = !!confirmedShifts?.length
            ? getShiftPayment(confirmedShifts?.[0])
            : '';
          break;
        }
        /**
         * task of first confirmed job of current contact
         */
        case '{{ job_task }}': {
          const confirmedShifts = await getConfirmedShifts(true);
          value = !!confirmedShifts?.length ? confirmedShifts[0].task : '';
          break;
        }
        /**
         * area of first confirmed job of current contact
         */
        case '{{ job_area }}': {
          const confirmedShifts = await getConfirmedShifts(true);
          value = !!confirmedShifts?.length ? confirmedShifts?.[0].area : '';
          break;
        }
        case '{{ jobs_list }}': {
          const shifts = await getShiftsCallback([]);
          value = getSummedShiftsCollectionMarkup(shifts);
          break;
        }
        case '{{ jobs_list_in_period }}': {
          if (!document?.start || !document?.end) {
            value = '';
            break;
          }
          const shiftsInPeriod = await getShiftsByPeriod(
            false,
            document.start,
            document.end
          );
          value = getSummedShiftsCollectionMarkup(shiftsInPeriod);
          break;
        }
        case '{{ jobs_list_amount }}': {
          const shifts = await getShiftsCallback([]);
          value = shifts.length.toString();
          break;
        }
        case '{{ jobs_list_in_period_amount }}': {
          if (!document?.start || !document?.end) {
            value = '';
            break;
          }
          const shiftsInPeriod = await getShiftsByPeriod(
            false,
            document.start,
            document.end
          );
          value = shiftsInPeriod.length.toString();
          break;
        }
        case '{{ jobs_list_with_location }}': {
          const shifts = await getShiftsCallback([]);
          // group shifts by mission
          // then display shifts of mission with mission address on top
          const shiftsByMission = shifts.reduce(
            (acc, shift) => {
              if (!acc[shift.mission.id]) {
                acc[shift.mission.id] = [];
              }
              acc[shift.mission.id].push(shift);
              return acc;
            },
            {} as Record<string, IProjectShift[]>
          );

          value = Object.entries(shiftsByMission)
            .map(([, missionShifts]) => {
              const currentMission = missionShifts[0].mission;
              return `<p>${getFullAddress(currentMission)}</p><br/>${getSummedShiftsCollectionMarkup(missionShifts)}`;
            })
            .join('<br/>');

          break;
        }
        case '{{ jobs_list_with_location_in_period }}': {
          if (!document?.start || !document?.end) {
            value = '';
            break;
          }
          const shiftsInPeriod = await getShiftsByPeriod(
            false,
            document.start,
            document.end
          );
          // group shifts by mission
          // then display shifts of mission with mission address on top
          const shiftsByMission = shiftsInPeriod.reduce(
            (acc, shift) => {
              if (!acc[shift.mission.id]) {
                acc[shift.mission.id] = [];
              }
              acc[shift.mission.id].push(shift);
              return acc;
            },
            {} as Record<string, IProjectShift[]>
          );

          value = Object.entries(shiftsByMission)
            .map(([, missionShifts]) => {
              const currentMission = missionShifts[0].mission;
              return `<p>${getFullAddress(currentMission)}</p><br/>${getSummedShiftsCollectionMarkup(missionShifts)}`;
            })
            .join('<br/>');

          break;
        }
        default:
          value = marker;
      }
      html = html.replace(marker, value ?? '');
    }
  }
  return html;
};

export { resolveMarkers };
