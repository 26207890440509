import { styled } from 'styled-components';
import { theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const SignatureContentStyled = styled.div`
  ${flexFn('flex-start', 'center', 'column')};
  gap: ${pxToRem(16)};
`;

export const SignatureWrapperStyled = styled.div`
  width: 100%;
  max-width: ${pxToRem(200)};
  ${flexFn('flex-start', 'start', 'column')};
  gap: ${pxToRem(8)};

  > img {
    width: 100%;
  }
`;

export const SignatureDescriptionStyled = styled.div`
  font-size: ${pxToRem(12)};
  font-weight: 800;
  color: ${theme.colors.primary.grey};
`;
