import { Utils } from 'team-hero-ui';
import { styled } from 'styled-components';

const { pxToRem } = Utils;

export const TagsCellContainerStyled = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const TagWrapperStyled = styled.div`
  padding: ${pxToRem([3, 2])};
`;
