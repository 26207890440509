import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IMissionDocumentsTabProps } from './MissionDocumentsTab.types';
import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import { type ISimpleFilter, useFilters } from 'hooks/useFilters.hook';
import { useAppSelector } from 'store/hooks';
import { useGetCompanyDocumentsQuery } from 'services/teamHeroApi.service';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import { usePagination } from 'hooks/usePagination.hook';
import {
  paginatedMissionCompanyDocumentsSelector,
  setItemsPerPage,
  setMenuColumnList,
  setNextPage,
  setSorting,
  setFilters,
} from 'redux/table/missionCompanyDocuments/missionCompanyDocuments.slice';
import { useSort } from 'components/TableView/hooks/useSort.hook';
import { useRowSelection } from 'components/TableView/hooks/useRowSelection.hook';
import { TableView } from 'components/TableView/TableView';
import {
  columnsOffset,
  companyDocumentsColumnsFactory,
} from 'config/tableColumnConfigurations/CompanyDocumentsColumns.config';
import { companyDocumentsFiltersFactory } from 'config/filterConfigurations/CompanyDocumentsFilters.config';
import { useClientDocumentModal } from 'components/ClientLoginDocumentModal/useClientDocumentModal.hook';

const MissionDocumentsTab: FC<IMissionDocumentsTabProps> = ({ missionId }) => {
  const { t } = useTranslation('general');

  const { pagination, sorting, filters } = useAppSelector(
    paginatedMissionCompanyDocumentsSelector
  );

  const missionFilters: ISimpleFilter[] = useMemo(
    () => [{ key: 'mission', operator: 'AND', value: missionId }],
    [missionId]
  );

  const {
    data: documents,
    isLoading,
    isFetching,
    isError,
  } = useGetCompanyDocumentsQuery({
    ...createPaginationApiArgs(pagination, sorting),
    filters: [...missionFilters, ...filters],
  });

  const paginationData = usePagination(
    1,
    setNextPage,
    setItemsPerPage,
    paginatedMissionCompanyDocumentsSelector
  );

  const sortingData = useSort(
    setSorting,
    paginatedMissionCompanyDocumentsSelector
  );

  const filterData = useFilters(
    setFilters,
    paginatedMissionCompanyDocumentsSelector,
    paginationData.onResetPage
  );

  const { rowSelectionProps, bulkEdit } = useRowSelection<ICompanyDocument>(
    documents?.items
  );

  const { openModal: openUpdateDocumentModal, modal: updateDocumentModal } =
    useClientDocumentModal();

  if (isError) {
    throw new Error(t('errors:errorOccurred'));
  }

  return (
    <>
      {updateDocumentModal}
      <TableView
        columns={companyDocumentsColumnsFactory({
          t,
        })}
        filterDefinitions={companyDocumentsFiltersFactory({
          t,
        })}
        filtersDisplayType={'modal'}
        filterData={filterData}
        bulkEditRow={bulkEdit}
        data={documents?.items || []}
        isLoading={isLoading}
        isFetching={isFetching}
        itemsTotalCount={documents?.totalCount || 0}
        pagination={paginationData}
        rowIdMenuOpen={rowSelectionProps.rowIdMenuOpen}
        columnsOffset={columnsOffset}
        sorting={sortingData}
        onRowClick={(id) => openUpdateDocumentModal(id)}
        isMin={false}
        paginationSelector={paginatedMissionCompanyDocumentsSelector}
        setColumnListDispatch={setMenuColumnList}
        disableColumnSelection
      />
    </>
  );
};

export default MissionDocumentsTab;
