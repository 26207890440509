import {
  useDeleteApplicationDocumentFileItemMutation,
  useDeleteCompanyDocumentFileItemMutation,
  useDeleteContactDocumentFileMutation,
  useGetApplicationDocumentFileEntityItemQuery,
  useGetCompanyDocumentFileEntityItemQuery,
  useGetContactDocumentFileQuery,
  usePostApplicationDocumentFileEntityItemMutation,
  usePostApplicationDocumentFileItemMutation,
  usePostCompanyDocumentFileEntityItemMutation,
  usePostCompanyDocumentFileItemMutation,
  usePostContactDocumentFileEntityMutation,
  usePostContactDocumentFileMutation,
  usePutApplicationDocumentItemMutation,
  usePutCompanyDocumentItemMutation,
  usePutContactDocumentMutation,
} from 'services/teamHeroApi.service';

export type TFileUploadType = 'contact' | 'application' | 'company';

const useFileUploads = (type: TFileUploadType) => {
  const [createContactFileEntity] = usePostContactDocumentFileEntityMutation();
  const [createContactFile] = usePostContactDocumentFileMutation();
  const [updateContactDocument] = usePutContactDocumentMutation();
  const [deleteContactDocumentFile] = useDeleteContactDocumentFileMutation();

  const [createApplicationFileEntity] =
    usePostApplicationDocumentFileEntityItemMutation();
  const [createApplicationFile] = usePostApplicationDocumentFileItemMutation();
  const [updateApplicationDocument] = usePutApplicationDocumentItemMutation();
  const [deleteApplicationDocumentFile] =
    useDeleteApplicationDocumentFileItemMutation();

  const [createCompanyFileEntity] =
    usePostCompanyDocumentFileEntityItemMutation();
  const [createCompanyFile] = usePostCompanyDocumentFileItemMutation();
  const [updateCompanyDocument] = usePutCompanyDocumentItemMutation();
  const [deleteCompanyDocumentFile] =
    useDeleteCompanyDocumentFileItemMutation();

  return {
    getDocument:
      type === 'contact'
        ? useGetContactDocumentFileQuery
        : type === 'company'
          ? useGetCompanyDocumentFileEntityItemQuery
          : useGetApplicationDocumentFileEntityItemQuery,
    createFile:
      type === 'contact'
        ? createContactFile
        : type === 'company'
          ? createCompanyFile
          : createApplicationFile,
    createFileEntity:
      type === 'contact'
        ? createContactFileEntity
        : type === 'company'
          ? createCompanyFileEntity
          : createApplicationFileEntity,
    updateDocument:
      type === 'contact'
        ? updateContactDocument
        : type === 'company'
          ? updateCompanyDocument
          : updateApplicationDocument,
    deleteDocument:
      type === 'contact'
        ? deleteContactDocumentFile
        : type === 'company'
          ? deleteCompanyDocumentFile
          : deleteApplicationDocumentFile,
    tagToInvalidate:
      type === 'contact'
        ? ('ContactDocument' as const)
        : type === 'company'
          ? ('CompanyDocument' as const)
          : ('ApplicationDocument' as const),
    iriPrefix:
      type === 'contact'
        ? '/api/contact_documents/'
        : type === 'company'
          ? '/api/company_documents/'
          : '/api/application_documents/',
    filePrefix:
      type === 'contact'
        ? 'api/contact_document_files/'
        : type === 'company'
          ? 'api/company_document_files/'
          : 'api/application_document_files/',
  };
};

export default useFileUploads;
