import { TFunction } from 'i18next';

import { ISelectValue } from 'interfaces/Data/SelectValue.interface';
import { TPaymentType } from 'interfaces/ProjectShift.interface';

export const getPaymentTypeOptions = (
  t: TFunction
): ISelectValue<TPaymentType>[] => [
  {
    label: `/${t('modal.labels.paymentHour')}`,
    value: 'per-hour',
  },
  {
    label: `/${t('modal.labels.paymentFlat')}`,
    value: 'flat',
  },
];
