import { TFunction } from 'i18next';

import { TContractType } from 'interfaces/Contact.interface';

export interface IContactSettingsOption {
  label: string;
  value: TContractType;
}

export const getContractSettingsOptions = (
  t: TFunction
): IContactSettingsOption[] => [
  {
    label: t('modal.labels.permanentEmployment'),
    value: 'permanent employment',
  },
  {
    label: t('modal.labels.partTimeEmployment'),
    value: 'part time employment',
  },
  {
    label: t('modal.labels.workingStudent'),
    value: 'working student',
  },
  {
    label: t('modal.labels.minijob'),
    value: 'minijob',
  },
  {
    label: t('modal.labels.internship'),
    value: 'internship',
  },
  {
    label: t('modal.labels.freelancer'),
    value: 'freelancer',
  },
  { label: t('modal.labels.trainee'), value: 'trainee' },
  {
    label: t('modal.labels.temporaryWorker'),
    value: 'temporary worker',
  },
  {
    label: t('modal.labels.externalConsultant'),
    value: 'external consultant',
  },
  {
    label: t('modal.labels.shortTermEmployment'),
    value: 'short-term employment',
  },
  {
    label: t('modal.labels.midijob'),
    value: 'midijob',
  },
];
