import moment from 'moment';
import { TFunction } from 'i18next';

interface IDisplayDurationAsTimeProps {
  t: TFunction;
  durationHours: number;
  displayPlusSign?: boolean;
  addSpaceBeforeUnit?: boolean;
}

export const displayDurationAsTime = ({
  t,
  durationHours,
  displayPlusSign = false,
  addSpaceBeforeUnit = false,
}: IDisplayDurationAsTimeProps) => {
  const duration = parseFloat(durationHours.toFixed(4)); //use 4 decimal places same as in backend

  // Before the decimal point is the hour, after the decimal point is the minute
  // Math.floor() is used to get the hour part
  // to get the right number of hours, we need to use Math.abs() to make sure the number is positive
  // the result will always be a positive number with no decimal point
  // Examples: 1.9 -> 1, -2.9 -> 2
  const hours = Math.floor(Math.abs(duration)).toString();

  // make sure minutes are positive
  // if duration is 23.9975, then minutes should still be 59 so we always round down
  const minutes = Math.abs(Math.floor((duration * 60) % 60)).toString();

  // display plus sign only if duration is positive and displayPlusSign is true
  // display minus sign only if duration is negative
  const plusMinusSign =
    displayPlusSign && durationHours > 0 ? '+' : durationHours < 0 ? '-' : '';

  if (addSpaceBeforeUnit) {
    return `${plusMinusSign}${hours} ${t('hourShort')} ${minutes} ${t('minuteShort')}`;
  }
  return `${plusMinusSign}${hours}${t('hourShort')} ${minutes}${t('minuteShort')}`;
};

interface IDisplayDurationAsTimeFromStartEndProps
  extends Omit<IDisplayDurationAsTimeProps, 'durationHours'> {
  start: string;
  end: string;
}

export const displayDurationAsTimeFromStartEnd = ({
  start,
  end,
  ...rest
}: IDisplayDurationAsTimeFromStartEndProps) => {
  const durationHours = moment(end).diff(moment(start), 'hours', true);
  return displayDurationAsTime({ durationHours, ...rest });
};
