import { TFunction } from 'i18next';
import { Theme } from 'team-hero-ui';

import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import type { IProjectCostsData } from 'interfaces/Widget/ReportProjectCostsWidget.interface';
import { GrayedCellRenderer } from 'components/cellRenderers/wta';
import { TimetrackingDurationCellRenderer } from 'components/cellRenderers/timetracking';

const customWidths = {
  S: '100px',
  M: '200px',
  L: '300px',
};

const {
  greyShades: { grey5 },
} = Theme.colors;

interface ITimetrackingsTopHeaderColumnsFactoryProps {
  t: TFunction;
  mainBarBgColor: string;
  hours: number;
  barDescription: string;
  enableClientContacts: boolean;
  bold?: boolean;
}

export const TimetrackingsBottomHeaderColumnsFactory = ({
  t,
  bold,
  hours,
  barDescription,
  mainBarBgColor,
  enableClientContacts,
}: ITimetrackingsTopHeaderColumnsFactoryProps): IColumnDefinitionType<IProjectCostsData>[] => {
  const getGreyedDarkEmptyCellRenderer = () => (
    <GrayedCellRenderer bgColor={mainBarBgColor} />
  );
  const getGreyedLightEmptyCellRenderer = () => (
    <GrayedCellRenderer bgColor={grey5} />
  );

  return [
    {
      key: 'status',
      label: '',
      visibility: true,
      width: '7px',
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'menu',
      label: '',
      visibility: true,
      width: '38px',
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'contact',
      label: t('table.header.labels.contact'),
      visibility: enableClientContacts,
      minWidth: customWidths.M,
      width: customWidths.M,
      displayInMenu: true,
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'shiftPosition',
      label: t('table.header.labels.position'),
      visibility: true,
      displayInMenu: true,
      minWidth: customWidths.M,
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'description',
      label: t('table.header.labels.date'),
      visibility: true,
      displayInMenu: true,
      width: '140px',
      isSortable: true,
      sortKey: 'start',
      cellType: 'custom',
      cellRenderer: () => (
        <GrayedCellRenderer
          bgColor={mainBarBgColor}
          color={Theme.colors.primary.white}
          bold={bold}
          withTextOverflow
        >
          {barDescription}
        </GrayedCellRenderer>
      ),
    },
    {
      key: 'start',
      label: t('table.header.labels.start'),
      visibility: true,
      displayInMenu: true,
      width: '90px',
      isSortable: true,
      cellType: 'custom',
      cellRenderer: getGreyedDarkEmptyCellRenderer,
    },
    {
      key: 'end',
      label: t('table.header.labels.end'),
      visibility: true,
      displayInMenu: true,
      width: '90px',
      isSortable: true,
      cellType: 'custom',
      cellRenderer: getGreyedDarkEmptyCellRenderer,
    },
    {
      key: 'duration',
      label: t('table.header.labels.hours'),
      visibility: true,
      displayInMenu: true,
      width: '100px',
      cellType: 'custom',
      cellRenderer: () => (
        <GrayedCellRenderer
          bgColor={mainBarBgColor}
          color={Theme.colors.primary.white}
          bold={bold}
          withoutLeftPadding
        >
          <TimetrackingDurationCellRenderer durationHours={hours} />
        </GrayedCellRenderer>
      ),
    },
    {
      key: 'note',
      label: t('table.header.labels.note'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      minWidth: customWidths.M,
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'externalApproval',
      label: t('table.header.labels.externalApproval'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      minWidth: customWidths.S,
      width: customWidths.S,
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'toDo',
      label: t('table.header.labels.menu'),
      visibility: true,
      displayInMenu: true,
      width: customWidths.S,
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
  ];
};
