import Joi from 'joi';
import { useTranslation } from 'react-i18next';

import {
  acceptUploadDocumentFiles,
  acceptUploadImageFiles,
} from 'config/acceptedUploadFiles.config';

interface IFileMimeTypeReturn {
  acceptDocumentFiles: string;
  acceptImageFiles: string;
  documentMimeTypeValidator: (required: boolean) => Joi.AnySchema<unknown>;
  imageMimeTypeValidator: (minFiles: number) => Joi.AnySchema<unknown>;
  allAvailableMimeTypeValidator: (required: boolean) => Joi.AnySchema<unknown>;
}

const maxFileNameLength = 80;

export const useFileMimeType = (): IFileMimeTypeReturn => {
  const { t } = useTranslation();
  const getFileTypeNames = (acceptedDocuments: string[] = []) =>
    acceptedDocuments
      .map((fileType) => `*.${fileType.split('/')[1]}`)
      .join(', ');

  const wrongTypeValidationError = 'file.type';
  const fileRequiredTypeError = 'file.required';
  const imageMinCountError = 'file.minImageCount';
  const fileNameTooLongError = 'fileName.tooLong';
  const acceptDocumentFiles = acceptUploadDocumentFiles.join(', ');
  const acceptImageFiles = acceptUploadImageFiles.join(', ');
  const availableDocumentFileTypeNames = getFileTypeNames(
    acceptUploadDocumentFiles
  );
  const availableImageFileTypeNames = getFileTypeNames(acceptUploadImageFiles);

  const validateFileNameLength = (
    files: File[],
    helpers: Joi.CustomHelpers<unknown>
  ) => {
    if (files && files.length > 0) {
      return files.some((file) => file.name.length > maxFileNameLength)
        ? helpers.error(fileNameTooLongError)
        : files;
    }

    return files;
  };

  const validateDocumentMimeType =
    (required = false) =>
    (files: File[], helpers: Joi.CustomHelpers<unknown>) => {
      if (files && files.length > 0) {
        return files.some(
          (file) => file.type && !acceptUploadDocumentFiles.includes(file.type)
          // If there is no type that means it's not currently added file its mockup of file already added to API.
        )
          ? helpers.error(wrongTypeValidationError)
          : files;
      }

      if (required) {
        return helpers.error(fileRequiredTypeError);
      }

      return files;
    };

  const validateImageMimeType =
    (minFiles = 0) =>
    (files: File[], helpers: Joi.CustomHelpers<unknown>) => {
      if (files && files.length >= minFiles) {
        return files.some(
          (file) => file.type && !acceptUploadImageFiles.includes(file.type)
        )
          ? helpers.error(wrongTypeValidationError)
          : files;
      }

      if (minFiles) {
        return helpers.error(imageMinCountError);
      }

      return files;
    };

  const validateAllMimeType =
    (required = false) =>
    (files: File[], helpers: Joi.CustomHelpers<unknown>) => {
      if (files && files.length > 0) {
        return files.some(
          (file) =>
            file.type &&
            ![...acceptUploadImageFiles, ...acceptUploadDocumentFiles].includes(
              file.type
            )
        )
          ? helpers.error(wrongTypeValidationError)
          : files;
      }

      if (required) {
        return helpers.error(fileRequiredTypeError);
      }

      return files;
    };

  const allAvailableMimeTypeValidator = (
    required = false
  ): Joi.AnySchema<unknown> =>
    Joi.custom(validateAllMimeType(required))
      .custom(validateFileNameLength)
      .messages({
        'file.type': t('modal.validation.availableFileTypes').replace(
          '{{fileTypes}}',
          `${availableDocumentFileTypeNames}, ${availableImageFileTypeNames}`
        ),
        'file.required': t('modal.validation.fieldRequired'),
        'fileName.tooLong': t('modal.validation.fileNameTooLong', {
          maxLength: maxFileNameLength,
        }),
      });

  const imageMimeTypeValidator = (minFiles = 0): Joi.AnySchema<unknown> => {
    return Joi.custom(validateImageMimeType(minFiles))
      .custom(validateFileNameLength)
      .messages({
        'file.type': t('modal.validation.availableFileTypes').replace(
          '{{fileTypes}}',
          availableImageFileTypeNames
        ),
        'fileName.tooLong': t('modal.validation.fileNameTooLong', {
          maxLength: maxFileNameLength,
        }),
        'file.required': t('modal.validation.fieldRequired'),
        'file.minImageCount': t('modal.validation.minImageCount', {
          minFiles,
        }),
      });
  };

  const documentMimeTypeValidator = (
    required = false
  ): Joi.AnySchema<unknown> =>
    Joi.custom(validateDocumentMimeType(required))
      .custom(validateFileNameLength)
      .messages({
        'file.type': t('modal.validation.availableFileTypes', {
          fileTypes: availableDocumentFileTypeNames,
        }),
        'fileName.tooLong': t('modal.validation.fileNameTooLong', {
          maxLength: maxFileNameLength,
        }),
      });

  return {
    acceptDocumentFiles,
    acceptImageFiles,
    imageMimeTypeValidator,
    documentMimeTypeValidator,
    allAvailableMimeTypeValidator,
  };
};
