import { Button, Icons, Loader } from 'team-hero-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IPdfDownloadButtonProps } from './PdfDownloadButton.types';

const PdfDownloadButton: FC<IPdfDownloadButtonProps> = ({
  isLoading,
  onClick,
}) => {
  const { t } = useTranslation('general');

  return (
    <Button
      color='grey'
      size='large'
      onClick={onClick}
      endIcon={
        isLoading ? (
          <Loader loaderType='static' loaderSize='tiny' />
        ) : (
          <Icons.DownloadIcon svgColor='currentColor' svgSize={20} />
        )
      }
    >
      {t('common.download')}
    </Button>
  );
};

export default PdfDownloadButton;
