import { useCallback } from 'react';
import { TFunction } from 'i18next';
import { exportToHtml, importFromHtmlOrMarkdown } from 'team-hero-ui';

import type { IContact, IContactEmbed } from 'interfaces/Contact.interface';
import type { IContactDocument } from 'interfaces/ContactDocument.interface';
import type { ICurrencyFormatOptions } from 'hooks/numbers/useCurrency.hook';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IDocumentTemplate } from 'interfaces/DocumentTemplate.interface';
import type { IProject } from 'interfaces/Project.interface';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type { ICompany } from 'interfaces/Company.interface';
import { resolveMarkers } from 'components/PdfDocuments/layouts/HtmlDocumentPdf/HtmlDocumentPdf.helpers';
import { getProjectIdFromIriOrEmbed } from 'helpers/projects/getProjectIdFromIriOrEmbed.helper';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import {
  useLazyGetCompanyItemQuery,
  useLazyGetContactItemQuery,
  useLazyGetProjectItemQuery,
  useLazyGetProjectShiftsQuery,
} from 'services/teamHeroApi.service';

interface IDocumentArgs
  extends Pick<
    IContactDocument,
    'start' | 'end' | 'description' | 'probationEnd'
  > {}

export interface IGetDocumentLayoutProps {
  t: TFunction;
  documentTemplate: IDocumentTemplate;
  mission?: IProjectMission;
  project?: IProject;
  contact?: IContact | IContactEmbed;
  company?: ICompany;
  documentArgs?: IDocumentArgs;
  currencyFormatter: (
    value: number,
    options?: ICurrencyFormatOptions
  ) => string;
}

interface IUseGetDocumentLayoutReturn {
  getDocumentLayoutString: (props: IGetDocumentLayoutProps) => Promise<string>;
}

const useGetDocumentLayout = (): IUseGetDocumentLayoutReturn => {
  const [triggerContact] = useLazyGetContactItemQuery();
  const [triggerProject] = useLazyGetProjectItemQuery();
  const [triggerCompany] = useLazyGetCompanyItemQuery();
  const [triggerShifts] = useLazyGetProjectShiftsQuery();

  const getContact = useCallback(
    (contactId?: number) => {
      if (!contactId) return Promise.resolve(undefined);
      return triggerContact(
        {
          id: contactId,
        },
        true
      ).unwrap();
    },
    [triggerContact]
  );

  const getShiftsByMission = useCallback(
    async (missionId: number, filters: ISimpleFilter[]) => {
      const response = await triggerShifts(
        {
          ...createPaginationApiArgs(false, {
            columnKey: 'start',
            direction: 'asc',
          }),
          filters: [
            ...filters,
            {
              key: 'mission',
              operator: 'AND',
              value: missionId,
            },
          ],
        },
        true
      ).unwrap();

      return response?.items || [];
    },
    [triggerShifts]
  );

  const getShiftsByProject = useCallback(
    async (projectId: number, filters: ISimpleFilter[]) => {
      const response = await triggerShifts(
        {
          ...createPaginationApiArgs(false, {
            columnKey: 'start',
            direction: 'asc',
          }),
          filters: [
            ...filters,
            {
              key: 'mission.project',
              operator: 'AND',
              value: projectId,
            },
          ],
        },
        true
      ).unwrap();

      return response?.items || [];
    },
    [triggerShifts]
  );

  const getProjectByMission = useCallback(
    (mission?: IProjectMission) => {
      if (!mission) return Promise.resolve(undefined);
      const projectId = getProjectIdFromIriOrEmbed(mission.project) || 0;
      return triggerProject(
        {
          id: projectId,
        },
        true
      ).unwrap();
    },
    [triggerProject]
  );

  const getCompany = useCallback(
    async (project?: IProject) => {
      return project && project.client
        ? triggerCompany(
            {
              id: project.client.id,
            },
            true
          ).unwrap()
        : undefined;
    },
    [triggerCompany]
  );

  const getDocumentLayoutString = useCallback(
    async ({
      documentTemplate,
      mission,
      project,
      documentArgs,
      contact: contactArg,
      company: companyArg,
      ...restProps
    }: IGetDocumentLayoutProps): Promise<string> => {
      if (mission) {
        const projectFromMission = await getProjectByMission(mission);

        const getShiftsCallback = (filters: ISimpleFilter[]) =>
          getShiftsByMission(mission.id, filters);

        // company is either set in document or needs to be fetched from project
        const company = companyArg || (await getCompany(projectFromMission));

        /**
         * if contact is set in document, use that
         * if not, fetch contact from company relation
         */
        const contact = contactArg
          ? await getContact(contactArg?.id)
          : await getContact(company?.primaryContact?.id);
        const text = importFromHtmlOrMarkdown(documentTemplate.body || '');
        const html = exportToHtml(text);

        const resolvedHtml = await resolveMarkers({
          input: html,
          documentTemplate,
          getShiftsCallback,
          mission,
          contact,
          document: documentArgs,
          project: projectFromMission,
          company,
          ...restProps,
        });

        return resolvedHtml;
      }

      if (project) {
        const company = await getCompany(project);

        /**
         * if contact is set in document, use that
         * if not, fetch contact from company relation
         */
        const contact = contactArg
          ? await getContact(contactArg?.id)
          : await getContact(company?.primaryContact?.id);
        const text = importFromHtmlOrMarkdown(documentTemplate.body || '');
        const html = exportToHtml(text);

        const getShiftsCallback = (filters: ISimpleFilter[]) =>
          getShiftsByProject(project.id, filters);

        const resolvedHtml = await resolveMarkers({
          input: html,
          documentTemplate,
          contact,
          getShiftsCallback,
          project,
          document: documentArgs,
          company,
          ...restProps,
        });

        return resolvedHtml;
      }

      // if no project or mission, then it's a general document
      if (!project && !mission) {
        const contact = contactArg
          ? await getContact(contactArg?.id)
          : companyArg?.primaryContact?.id
            ? await getContact(companyArg?.primaryContact?.id)
            : undefined;

        const company = companyArg || undefined;

        const text = importFromHtmlOrMarkdown(documentTemplate.body || '');
        const html = exportToHtml(text);

        const resolvedHtml = await resolveMarkers({
          input: html,
          documentTemplate,
          document: documentArgs,
          contact,
          company,
          ...restProps,
        });

        return resolvedHtml;
      }
      return '';
    },
    [
      getCompany,
      getContact,
      getProjectByMission,
      getShiftsByMission,
      getShiftsByProject,
    ]
  );

  return {
    getDocumentLayoutString,
  };
};

export default useGetDocumentLayout;
