import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type { ICollectionApiResponse } from 'services/api/api.types';
import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import type {
  ICompanyDocumentCollectionArgs,
  ICompanyDocumentCollectionResponse,
  IGetCompanyDocumentArgs,
  IPutCompanyDocumentItemArgs,
} from './companyDocuments.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getCompanyDocuments = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  ICompanyDocumentCollectionArgs,
  BaseQueryFn,
  string,
  ICompanyDocumentCollectionResponse
> =>
  build.query<
    ICompanyDocumentCollectionResponse,
    ICompanyDocumentCollectionArgs
  >({
    providesTags: ['CompanyDocument'],
    query: (queryArg: ICompanyDocumentCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/company_documents${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<ICompanyDocument>
    ): ICompanyDocumentCollectionResponse =>
      transformCollectionResponse<ICompanyDocument>(response),
  });

export const getCompanyDocumentItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetCompanyDocumentArgs,
  BaseQueryFn,
  string,
  ICompanyDocument
> =>
  build.query<ICompanyDocument, IGetCompanyDocumentArgs>({
    providesTags: ['CompanyDocument'],
    query: (queryArg: IGetCompanyDocumentArgs) => ({
      url: `api/company_documents/${queryArg.id}`,
    }),
  });

export const putCompanyDocumentItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutCompanyDocumentItemArgs,
  BaseQueryFn,
  string,
  ICompanyDocument
> =>
  build.mutation<ICompanyDocument, IPutCompanyDocumentItemArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['CompanyDocument'],
    query: (queryArg) => ({
      url: `api/company_documents/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
