import { FC } from 'react';
import { Skeleton } from 'team-hero-ui';

import { TagsCellRenderer } from '../TagsCellRenderer/TagsCellRenderer';
import { useGetCompanyItemQuery } from 'services/teamHeroApi.service';

interface ICompanyNameTagCellRendererProps {
  companyIri?: string;
}

export const CompanyNameTagCellRenderer: FC<
  ICompanyNameTagCellRendererProps
> = ({ companyIri }) => {
  const companyId = companyIri
    ? parseInt(companyIri?.replace('/api/companies/', ''), 10)
    : null;

  const { data: companyData } = useGetCompanyItemQuery(
    {
      id: companyId || 0,
    },
    {
      skip: !companyId,
    }
  );

  return (
    <>
      {companyData ? (
        <TagsCellRenderer tags={companyData?.name ? [companyData.name] : []} />
      ) : (
        <Skeleton.TypographyH1 width={'50%'} />
      )}
    </>
  );
};
