import {
  BaseQueryFn,
  QueryDefinition,
  MutationDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type {
  IContactDocumentFile,
  IContactDocumentFileArgs,
  IDeleteContactDocumentFileEntityArgs,
  IPostContactDocumentFileArgs,
  IPostContactDocumentFileEntityArgs,
} from './contactDocumentFiles.api.types';

export const getContactDocumentFile = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IContactDocumentFileArgs,
  BaseQueryFn,
  string,
  IContactDocumentFile
> =>
  build.query<IContactDocumentFile, IContactDocumentFileArgs>({
    providesTags: ['ContactDocumentFile'],
    query: (queryArg: IContactDocumentFileArgs) => ({
      url: `/api/contact_document_files/${queryArg.id}`,
    }),
  });

export const postContactDocumentFile = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactDocumentFileArgs,
  BaseQueryFn,
  string,
  File
> =>
  build.mutation<File, IPostContactDocumentFileArgs>({
    invalidatesTags: ['ContactDocumentFile'],
    query: (queryArg) => ({
      url: `api/contact_document_files/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const postContactDocumentFileEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactDocumentFileEntityArgs,
  BaseQueryFn,
  string,
  IContactDocumentFile
> =>
  build.mutation<IContactDocumentFile, IPostContactDocumentFileEntityArgs>({
    invalidatesTags: ['ContactDocumentFile'],
    query: (queryArg) => ({
      url: `api/contact_document_files`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const deleteContactDocumentFile = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteContactDocumentFileEntityArgs,
  BaseQueryFn,
  string,
  IContactDocumentFile
> =>
  build.mutation<IContactDocumentFile, IDeleteContactDocumentFileEntityArgs>({
    invalidatesTags: ['ContactDocumentFile'],
    query: (queryArg) => ({
      url: `api/contact_document_files/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
