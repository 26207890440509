import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'team-hero-ui';

import useGetImageUrl from 'hooks/useGetImageUrl.hook';
import {
  SignatureContentStyled,
  SignatureDescriptionStyled,
  SignatureWrapperStyled,
} from './SignatureContent.styled';

interface ISignatureImageProps {
  fileUrl: string;
  alt: string;
}

const SignatureImage: FC<ISignatureImageProps> = ({ fileUrl, alt }) => {
  const { imageUrls, isLoading } = useGetImageUrl({
    images: fileUrl,
    imageType: 'file',
  });

  const imageUrl = Array.isArray(imageUrls) ? imageUrls[0] : imageUrls;

  if (isLoading) {
    return <Loader loaderType={'static'} loaderSize={'small'} />;
  }

  return <img src={imageUrl} alt={alt} />;
};

interface ISignatureContentProps {
  approvalSignature?: string;
}

const SignatureContent: FC<ISignatureContentProps> = ({
  approvalSignature,
}) => {
  const { t } = useTranslation();

  return (
    <SignatureContentStyled>
      {approvalSignature && (
        <SignatureWrapperStyled>
          <SignatureDescriptionStyled>
            {t('approvalSignatureFile.description')}
          </SignatureDescriptionStyled>
          <SignatureImage
            fileUrl={approvalSignature}
            alt={t('approvalSignatureFile.description')}
          />
        </SignatureWrapperStyled>
      )}
    </SignatureContentStyled>
  );
};

export default SignatureContent;
