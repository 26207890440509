import type {
  ICurrency,
  TCurrencyName,
  TCurrencySymbol,
} from './currency.types';
import { convertToCents } from 'helpers/currency/convertToCents.helper';
import { CURRENCIES } from './currency.contants';

export default class CurrencyService {
  static convertToBackend = (
    currency: string | number | undefined
  ): number | null => {
    switch (typeof currency) {
      case 'number':
        return Math.round(currency * 100);
      case 'string': {
        const result = convertToCents(currency);

        if (Number.isNaN(result)) {
          return null;
        }

        return result;
      }
      default:
        return null;
    }
  };

  static convertToFrontend = (
    currency: string | number | undefined
  ): number | undefined => {
    switch (typeof currency) {
      case 'number':
        if (currency < 0) return undefined;
        return currency / 100;
      case 'string': {
        const currencyAsNumber = Number(currency);
        if (isNaN(currencyAsNumber) || currencyAsNumber < 0) return undefined;
        return currencyAsNumber / 100;
      }
      default:
        return undefined;
    }
  };

  static getCurrencySymbol = (
    currencyName?: TCurrencyName
  ): TCurrencySymbol => {
    const currency = CURRENCIES.find((c) => c.name === currencyName);
    if (!currency) {
      return (CURRENCIES.find((c) => c.name === 'EUR') as ICurrency).symbol;
    }
    return currency.symbol;
  };
}
