import { FC } from 'react';
import { IconButton, Icons } from 'team-hero-ui';

import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import { CellContainerStyled } from 'components/cellRenderers/Common.styled';
import { useDocumentStatus } from 'hooks/useDocumentStatus.hooks';
import {
  IconAreaStyled,
  IconWrapperStyled,
  StatusStyled,
} from './DocumentStatusCellRenderer.styled';

interface ISignatureStatusCellRendererProps {
  document: ICompanyDocument;
}

const iconSize = 20;

export const DocumentStatusCellRenderer: FC<
  ISignatureStatusCellRendererProps
> = ({ document }) => {
  const { isMissing } = document;

  const {
    statusColor,
    isSigned,
    isWaitingToApproval,
    isExpired,
    isWaitingForUserAction,
    statusLabel,
  } = useDocumentStatus(document);

  return (
    <CellContainerStyled>
      <IconAreaStyled>
        {statusColor && (
          <IconWrapperStyled $bgColor={statusColor}>
            {isWaitingForUserAction || isMissing ? (
              <IconButton>
                <Icons.ExclamationMarkIcon
                  svgSize={iconSize}
                  svgColor={statusColor}
                />
              </IconButton>
            ) : isSigned ? (
              <IconButton>
                <Icons.CheckIconV2 svgSize={iconSize} svgColor={statusColor} />
              </IconButton>
            ) : isWaitingToApproval ? (
              <IconButton>
                <Icons.WaitingIcon svgColor={statusColor} svgSize={iconSize} />
              </IconButton>
            ) : isExpired ? (
              <IconButton>
                <Icons.ClockIcon svgSize={iconSize} svgColor={statusColor} />
              </IconButton>
            ) : null}
          </IconWrapperStyled>
        )}
        <StatusStyled $color={statusColor}>{statusLabel}</StatusStyled>
      </IconAreaStyled>
    </CellContainerStyled>
  );
};
