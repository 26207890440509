import { ReactElement, useCallback, useMemo, useState } from 'react';
import { SideModal } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import { ClientLoginDocumentModal } from 'components/ClientLoginDocumentModal/ClientLoginDocumentModal';
import {
  useGetCompanyDocumentFileEntityItemQuery,
  useGetCompanyDocumentItemQuery,
} from 'services/teamHeroApi.service';

interface IUseClientDocumentModalReturn {
  modal: ReactElement;
  openModal: (companyDocumentId: number) => void;
}

export const useClientDocumentModal = (): IUseClientDocumentModalReturn => {
  const { t } = useTranslation();

  const [documentId, setDocumentId] = useState<number | null>(null);

  const openModal = useCallback((companyDocumentId: number) => {
    setDocumentId(companyDocumentId);
  }, []);

  const handleCloseModal = useCallback(() => {
    setDocumentId(null);
  }, []);

  const { currentData: document, isFetching: isFetchingDocument } =
    useGetCompanyDocumentItemQuery(
      { id: documentId || 0 },
      { skip: !documentId }
    );

  const documentFileId = useMemo(() => {
    return document?.file
      ? parseInt(document.file.replace('/api/company_document_files/', ''), 10)
      : undefined;
  }, [document]);

  const { currentData: fileEntity, isFetching: isFetchingDocumentFile } =
    useGetCompanyDocumentFileEntityItemQuery(
      {
        id: documentFileId ? Number(documentFileId) : 0,
      },
      { skip: !documentFileId }
    );

  const modal = useMemo(() => {
    return (
      <SideModal
        isOpen={!!documentId}
        onSave={() => {}}
        onSaveAndClose={() => {}}
        onClose={handleCloseModal}
        popupDirection='left'
        titleLabel={t('work.modals.documents.title')}
      >
        <ClientLoginDocumentModal
          document={document}
          fileEntity={fileEntity}
          isLoading={isFetchingDocument || isFetchingDocumentFile}
        />
      </SideModal>
    );
  }, [
    document,
    documentId,
    fileEntity,
    handleCloseModal,
    isFetchingDocument,
    isFetchingDocumentFile,
    t,
  ]);

  return {
    modal,
    openModal,
  };
};
