import { TFunction } from 'i18next';
import { Theme } from 'team-hero-ui';

import type { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';
import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import type { IRowSelectionProps } from 'components/TableView/hooks/useRowSelection.hook';
import { getDurationAsHoursByDateString } from 'components/Timeline/helper/timelineItemsCalculate.helper';
import { StatusCellRenderer } from 'components/cellRenderers/StatusCellRenderer/StatusCellRenderer';
import ContactCellRenderer from 'components/cellRenderers/ContactCellRenderer';
import {
  TimesheetExternallyApprovedCellRenderer,
  TimetrackingDurationCellRenderer,
  TimetrackingPositionCellRenderer,
  TimetrackingToDoCellRenderer,
} from 'components/cellRenderers/timetracking';
import { DateCellRenderer } from 'components/cellRenderers/DateCellRenderer/DateCellRenderer';
import { DurationCellRenderer } from 'components/cellRenderers/DurationCellRenderer/DurationCellRenderer';
import { MenuCellRenderer } from 'components/cellRenderers/MenuCellRenderer/MenuCellRenderer';

const customWidths = {
  S: '100px',
  M: '200px',
  L: '300px',
};

interface IMissionsColumnsFactoryProps {
  displayContactAsDefault: boolean;
  t: TFunction;
  rowSelectionProps: IRowSelectionProps;
}

export const columnsOffset = 3;

export const getClientTimesheetStatusColor = (
  timesheet: IProjectTimesheet
): string => {
  switch (timesheet.isExternallyApproved) {
    case false:
      return Theme.colors.primary.red;
    case true:
    default:
      return Theme.colors.others.green.green1;
  }
};

export const timetrackingsColumnsFactory = ({
  displayContactAsDefault,
  t,
  rowSelectionProps,
}: IMissionsColumnsFactoryProps): IColumnDefinitionType<IProjectTimesheet>[] => {
  return [
    {
      key: 'status',
      label: '',
      visibility: true,
      width: '7px',
      skeletonType: 'status',
      cellType: 'custom',
      cellRenderer: (item) =>
        StatusCellRenderer(getClientTimesheetStatusColor(item)),
    },
    {
      key: 'menu',
      label: '',
      visibility: true,
      width: '38px',
      skeletonType: 'empty',
      cellType: 'custom',
      cellRenderer: (row) => (
        <MenuCellRenderer row={row} rowSelectionProps={rowSelectionProps} />
      ),
    },
    {
      key: 'contact',
      label: t('table.header.labels.contact'),
      visibility: displayContactAsDefault,
      displayInExport: displayContactAsDefault,
      minWidth: customWidths.M,
      width: customWidths.M,
      displayInMenu: true,
      cellType: 'custom',
      skeletonType: 'contact',
      cellRenderer: (timetracking) => (
        <ContactCellRenderer contactData={timetracking.contact} />
      ),
      cellExportHandler: ({ contact }) =>
        `${contact?.firstName} ${contact?.lastName}`,
    },
    {
      key: 'shiftPosition',
      label: t('table.header.labels.position'),
      visibility: true,
      displayInMenu: true,
      minWidth: customWidths.M,
      cellType: 'custom',
      cellRenderer: (timetracking) =>
        TimetrackingPositionCellRenderer(timetracking.shift?.position),
      cellExportHandler: (timetracking) => timetracking.shift?.position || '-',
    },
    {
      key: 'date',
      label: t('table.header.labels.date'),
      visibility: true,
      displayInMenu: true,
      width: '140px',
      isSortable: true,
      sortType: 'date',
      sortKey: 'start',
      cellType: 'custom',
      cellRenderer: (timetracking) =>
        DateCellRenderer({ date: timetracking.start, showTime: false }),
    },
    {
      key: 'time',
      label: t('table.header.labels.time'),
      visibility: true,
      displayInMenu: true,
      width: '180px',
      isSortable: true,
      cellType: 'custom',
      cellRenderer: (timetracking) => (
        <DurationCellRenderer
          start={timetracking.start}
          end={timetracking.end}
        />
      ),
      cellExportHandler: ({ start, end }) => `
        ${start ? new Date(start) : '-'}
        -
        ${end ? new Date(end) : '-'}
      `,
    },
    {
      key: 'duration',
      label: t('table.header.labels.hours'),
      visibility: true,
      displayInMenu: true,
      width: '100px',
      cellType: 'custom',
      cellRenderer: (timetracking) => (
        <TimetrackingDurationCellRenderer
          start={timetracking?.start || ''}
          end={timetracking?.end || ''}
        />
      ),
      cellExportHandler: ({ start, end }) => {
        if (!start || !end) return '-';

        return Number(getDurationAsHoursByDateString(start, end).toFixed(2));
      },
    },
    {
      key: 'note',
      label: t('table.header.labels.note'),
      visibility: true,
      displayInMenu: true,
      cellType: 'string',
      minWidth: customWidths.M,
    },
    {
      key: 'externalApproval',
      label: t('table.header.labels.externalApproval'),
      visibility: true,
      displayInMenu: true,
      cellType: 'custom',
      minWidth: customWidths.S,
      width: customWidths.S,
      cellRenderer: (timetracking) => (
        <TimesheetExternallyApprovedCellRenderer
          timesheetId={timetracking.id}
        />
      ),
    },
    {
      key: 'toDo',
      label: t('table.header.labels.menu'),
      visibility: true,
      displayInMenu: true,
      width: customWidths.S,
      minWidth: customWidths.S,
      cellType: 'custom',
      cellRenderer: (timetracking) =>
        TimetrackingToDoCellRenderer(
          timetracking.isExternallyApproved,
          timetracking.shift?.hasTimetrackingsForExternalApproval
        ),
    },
  ];
};
