import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import createFileUrl from 'helpers/file/getFile.helper';
import useNotification from '../useNotification.hook';

const useGetFile = (): {
  isFetching: boolean;
  downloadFileBySource: (
    fileName?: string | null,
    fileSource?: string | null
  ) => void;
  downloadFile: (file: File) => void;
} => {
  const { t } = useTranslation('general');
  const [isFetching, setIsFetching] = useState(false);
  const { set: setNotification } = useNotification();

  const onFileDownloadError = useCallback(() => {
    setNotification({
      type: 'error',
      message: t('action.downloadFileError'),
    });
  }, [setNotification, t]);

  const downloadFile = useCallback((file: File): void => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, []);

  const downloadFileBySource = useCallback(
    (fileName?: string | null, fileSource?: string | null): void => {
      if (fileSource && fileName) {
        setIsFetching(true);

        const url = fileSource.replace('/api/', 'api/');

        createFileUrl({ apiRequestUrl: url })
          .then((fileUrl) => {
            const link = document.createElement('a');

            link.href = fileUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(() => {
            onFileDownloadError();
          })
          .finally(() => {
            setIsFetching(false);
          });
      } else {
        onFileDownloadError();
      }
    },
    [onFileDownloadError]
  );

  return {
    isFetching,
    downloadFileBySource,
    downloadFile,
  };
};

export default useGetFile;
