import moment, { Duration, Moment } from 'moment';

import { getOutputNumber } from 'helpers/number/numberToString.helper';
import { endOfDay, startOfDay } from './startOfEndOf.helper';

export const getDuration = (
  start?: string | Moment,
  end?: string | Moment,
  freeDays = 0,
  roundStartEndDate = true
): Duration | null => {
  if (start && end) {
    if (roundStartEndDate) {
      return moment.duration(
        endOfDay(moment(end))
          .subtract(freeDays, 'days')
          .diff(startOfDay(moment(start)))
      );
    } else {
      return moment.duration(
        moment(end).subtract(freeDays, 'days').diff(moment(start))
      );
    }
  }
  return null;
};

export const getDurationAsDaysString = (duration: Duration | null): string =>
  getOutputNumber({ value: Math.max(duration?.asDays() || 0, 0) });

export const getDurationAsHoursString = (duration: Duration | null): string =>
  getOutputNumber({ value: Math.max(duration?.asHours() || 0, 0) });
