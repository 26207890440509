import { FC } from 'react';
import { IconButton, Icons, Loader, Theme } from 'team-hero-ui';

import type { ICompanyDocumentDownloadCellRendererProps } from './CompanyDocumentDownloadCellRenderer.types';
import { DownloadCellContainerStyled } from './CompanyDocumentDownloadCellRenderer.styled';
import { useGetCompanyDocumentFileEntityItemQuery } from 'services/teamHeroApi.service';
import { useCompanyPdfDocument } from 'components/PdfDocuments/renderers/CompanyDocument/useCompanyPdfDocument.hook';
import useGetFile from 'hooks/useGetFileHook/useGetFileHook';
import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';

export const CompanyDocumentDownloadCellRenderer: FC<
  ICompanyDocumentDownloadCellRendererProps
> = ({ document }) => {
  const { downloadFileBySource } = useGetFile();

  const fileId = document.file
    ? parseInt(document.file.replace('/api/company_document_files/', ''), 10)
    : undefined;

  const { data } = useGetCompanyDocumentFileEntityItemQuery(
    {
      id: fileId,
    },
    { skip: !fileId }
  );

  const { saveCompanyPdfFile, isLoading } = useCompanyPdfDocument();

  if (
    document.template.type === 'uploadable' &&
    (!document.file || !data?.file)
  )
    return null;

  const getPdfFile = () => {
    if (document.template.type !== 'uploadable' && !document.file) {
      saveCompanyPdfFile(document);
    } else {
      downloadFileBySource(data?.originalFileName, data?.file);
    }
  };

  return (
    <DownloadCellContainerStyled>
      {isLoading ? (
        <Loader loaderSize='tiny' loaderType='static' />
      ) : (
        <IconButton onClick={(e) => preventEventWrapper(e, getPdfFile)}>
          <Icons.DownloadIcon
            svgSize={30}
            svgColor={Theme.colors.primary.grey}
          />
        </IconButton>
      )}
    </DownloadCellContainerStyled>
  );
};
