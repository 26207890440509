import type { ICompanyEmbed } from 'interfaces/Company.interface';
import { checkEmbedIsCompany } from 'helpers/embed/embedTypeCheck.helper';

export const getCompanyIdFromIriOrCompany = (
  company?: string | ICompanyEmbed | number
): number | undefined => {
  if (!company) {
    return undefined;
  }
  if (typeof company === 'number') {
    return company;
  }
  if (checkEmbedIsCompany(company)) {
    return company.id;
  }
  const id = parseInt(company.replace('/api/companies/', ''), 10);
  if (isNaN(id)) {
    return undefined;
  }
  return id;
};

export const getCompanyIriFromIriOrCompany = (
  company: string | ICompanyEmbed
): string => {
  if (checkEmbedIsCompany(company)) {
    return company['@id'];
  }
  return company;
};
