import { ReactSignatureCanvas, SignaturePadPoint } from 'team-hero-ui';

const getBlackPenColorCanvas = (
  signatureCanvas: ReactSignatureCanvas
): HTMLCanvasElement => {
  const signaturePad = signatureCanvas.getSignaturePad();
  const signaturePadData = signaturePad.toData() as SignaturePadPoint[][];

  signatureCanvas.fromData(
    signaturePadData.map((data) => {
      const newData = data.map((point) => ({
        ...point,
        color: 'black',
      }));
      return newData as SignaturePadPoint[];
    })
  );

  return signatureCanvas.getTrimmedCanvas();
};

export default getBlackPenColorCanvas;
