import { FC } from 'react';
import {
  ClickAwayListener,
  IconButton,
  Icons,
  Loader,
  Tooltip,
} from 'team-hero-ui';

import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';
import { useToggle } from 'hooks/useToggle.hook';
import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';
import SignatureContent from './SignatureContent';
import { useGetProjectTimesheetItemQuery } from 'services/teamHeroApi.service';

interface ITimesheetExternallyApprovedCellRendererProps {
  timesheetId: number;
}

export const TimesheetExternallyApprovedCellRenderer: FC<
  ITimesheetExternallyApprovedCellRendererProps
> = ({ timesheetId }) => {
  const {
    data: timesheet,
    isLoading,
    isError,
  } = useGetProjectTimesheetItemQuery({
    id: timesheetId,
  });

  const { toggle, toggleValue, off } = useToggle(false);

  const hasApprovalSignature: boolean =
    !!timesheet &&
    !!timesheet.approvalSignature &&
    !!timesheet.isExternallyApproved;

  if (isLoading) {
    return <Loader loaderSize={'tiny'} loaderType={'static'} />;
  }

  if (!timesheet || isError || !hasApprovalSignature) {
    return <EmptyCell />;
  }

  return (
    <ClickAwayListener onClickAway={() => off()}>
      <div>
        <Tooltip
          open={toggleValue}
          title={
            <SignatureContent
              approvalSignature={
                !!timesheet.approvalSignature
                  ? `${timesheet.approvalSignature}/file`
                  : undefined
              }
            />
          }
          displayArrow
          styleType={'left'}
        >
          <div>
            <IconButton
              size='small'
              onClick={(e) => preventEventWrapper(e, toggle)}
            >
              <Icons.TimeTrackingSignedV1ExIcon svgSize={24} />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
