import type { ICurrency } from './currency.types';

export const CURRENCIES: ICurrency[] = [
  {
    name: 'USD',
    symbol: '$',
  },
  {
    name: 'EUR',
    symbol: '€',
  },
  {
    name: 'CHF',
    symbol: 'CHF',
  },
];
