import { pdf } from '@react-pdf/renderer';
import { useCallback, useState } from 'react';

import { useErrorHandler } from './useDisplayApiError.hook';
import useDownloadFile from 'hooks/useDownloadFile.hook';

interface ISavePdfFileArgs {
  fileName: string;
  getPdfLayout: () => Promise<JSX.Element>;
}

interface IUseSavePdfFileReturn {
  isLoading: boolean;
  savePdfFile: (args: ISavePdfFileArgs) => Promise<void>;
}

const useSavePdfFile = (): IUseSavePdfFileReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleError } = useErrorHandler();
  const { triggerBlobDownload } = useDownloadFile();

  const savePdfFile = useCallback(
    async ({ getPdfLayout, fileName }: ISavePdfFileArgs) => {
      setIsLoading(true);

      try {
        const document = await getPdfLayout();
        const pdfFile = pdf(<></>);
        pdfFile.updateContainer(document);
        // save depending on platform
        const blob = await pdfFile.toBlob();
        await triggerBlobDownload(blob, `${fileName}.pdf`);
      } catch (e) {
        handleError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, triggerBlobDownload]
  );

  return {
    isLoading,
    savePdfFile,
  };
};

export default useSavePdfFile;
