import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const IconAreaStyled = styled.div`
  height: inherit;
  ${flexFn()};
  gap: ${pxToRem(8)};
`;

export const IconWrapperStyled = styled.div<{ $bgColor: string }>`
  border-radius: ${pxToRem(20)};
  background-color: ${({ $bgColor }) => `${$bgColor}60`};
  ${flexFn()};
`;

interface IStatusStyledProps {
  $color: string;
}

export const StatusStyled = styled.p<IStatusStyledProps>`
  color: ${({ $color }) => $color};
  font-size: ${pxToRem(11)};
`;
