import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const DownloadCellContainerStyled = styled.div`
  ${flexFn('flex-end')}
  width: 100%;
  padding-right: ${pxToRem(20)};
`;
