export const convertToCents = (numberString: string): number => {
  const numberContainsComma = numberString.includes(',');

  if (!numberContainsComma) {
    return Math.round(parseFloat(numberString) * 100);
  }

  const thousandsSeparator =
    numberString.indexOf(',') < numberString.indexOf('.') ? ',' : '.';

  // Step 1: Remove thousands separators
  const withoutThousands = numberString.replaceAll(thousandsSeparator, '');

  // Step 2: Replace comma with dot
  const withDot = withoutThousands.replace(',', '.');

  // Step 3: Multiply by 100
  const cents = parseFloat(withDot) * 100;

  return Math.round(cents);
};
