import { Icons, Theme } from 'team-hero-ui';
import { FC } from 'react';

import type { TSignatureStatus } from 'interfaces/ContactDocument.interface';
import type { IApplicationDocumentFileEmbed } from 'interfaces/ApplicationDocument.interface';
import { ToDoCellContainerStyled } from './DocumentTodoCellRenderer.styled';

interface IDocumentTodoCellRendererProps {
  isApproved?: boolean;
  isExpired?: boolean;
  isMissing?: boolean;
  signatureStatus?: TSignatureStatus;
  file?: string | null | IApplicationDocumentFileEmbed;
}

export const DocumentTodoCellRenderer: FC<IDocumentTodoCellRendererProps> = ({
  isApproved,
  isExpired,
  isMissing,
  signatureStatus,
  file,
}) => {
  const svgSize = 30;

  return (
    <ToDoCellContainerStyled>
      {!isApproved && !!file && (
        <Icons.ViewIcon svgSize={svgSize} svgColor={Theme.colors.primary.red} />
      )}
      {isMissing && (
        <Icons.UploadIcon
          svgSize={svgSize}
          svgColor={Theme.colors.primary.red}
        />
      )}
      {isExpired && (
        <Icons.DocumentExpiredIcon
          svgSize={svgSize}
          svgColor={Theme.colors.primary.red}
        />
      )}
      {[
        'waitingForSignature1',
        'waitingForSignature2',
        'waitingForContact',
      ].includes(signatureStatus || '') && (
        <Icons.TimeTrackingSignedV1ExIcon
          svgSize={svgSize}
          svgColor={Theme.colors.primary.red}
        />
      )}
      {['declined', 'expired', 'canceled'].includes(signatureStatus || '') && (
        <Icons.CancelIcon
          svgSize={svgSize}
          svgColor={Theme.colors.primary.red}
        />
      )}
    </ToDoCellContainerStyled>
  );
};
