import { ReactElement, useCallback } from 'react';
import { TFunction } from 'i18next';

import type { IDocumentTemplate } from 'interfaces/DocumentTemplate.interface';
import type { ICurrencyFormatOptions } from 'hooks/numbers/useCurrency.hook';
import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import useGetDocumentLayout from 'components/PdfDocuments/renderers/Layout/useGetDocumentLayout.hook';
import { getProjectIdFromIriOrEmbed } from 'helpers/projects/getProjectIdFromIriOrEmbed.helper';
import { getMissionIdFromIriOrEmbed } from 'helpers/missions/getMissionIdFromIriOrEmbed.helper';
import {
  useLazyGetCompanyItemQuery,
  useLazyGetDocumentTemplateItemQuery,
  useLazyGetProjectItemQuery,
  useLazyGetProjectMissionItemQuery,
} from 'services/teamHeroApi.service';
import HtmlDocumentPdf from '../../layouts/HtmlDocumentPdf';
import { getCompanyIdFromIriOrCompany } from 'helpers/company/getCompanyId.helper';

interface IGetLayoutProps {
  t: TFunction;
  documentTemplate?: IDocumentTemplate;
  document?: ICompanyDocument;
  currencyFormatter: (
    value: number,
    options?: ICurrencyFormatOptions
  ) => string;
}

interface IUseGetCompanyDocumentPdfLayoutReturn {
  getCompanyDocumentPdfLayout: (
    props: IGetLayoutProps
  ) => Promise<ReactElement>;
}

const useGetCompanyDocumentPdfLayout =
  (): IUseGetCompanyDocumentPdfLayoutReturn => {
    const [triggerDocumentTemplate] = useLazyGetDocumentTemplateItemQuery();
    const [triggerMission] = useLazyGetProjectMissionItemQuery();
    const [triggerProject] = useLazyGetProjectItemQuery();
    const [triggerCompany] = useLazyGetCompanyItemQuery();

    const getMission = useCallback(
      (missionIri?: string) => {
        if (!missionIri) return Promise.resolve(undefined);
        return triggerMission(
          {
            id: getMissionIdFromIriOrEmbed(missionIri) || null,
          },
          true
        ).unwrap();
      },
      [triggerMission]
    );

    const getProject = useCallback(
      (projectIri?: string) => {
        if (!projectIri) return Promise.resolve(undefined);
        return triggerProject(
          {
            id: getProjectIdFromIriOrEmbed(projectIri) || 0,
          },
          true
        ).unwrap();
      },
      [triggerProject]
    );

    const getCompany = useCallback(
      (companyIri?: string) => {
        if (!getCompanyIdFromIriOrCompany(companyIri))
          return Promise.resolve(undefined);
        return triggerCompany(
          {
            id: getCompanyIdFromIriOrCompany(companyIri) || 0,
          },
          true
        ).unwrap();
      },
      [triggerCompany]
    );

    const getDocumentTemplate = useCallback(
      async (
        documentTemplate?: IDocumentTemplate,
        documentTemplateId?: number
      ): Promise<IDocumentTemplate | null> => {
        if (documentTemplate) return documentTemplate;
        if (!documentTemplateId) return null;
        return triggerDocumentTemplate(
          {
            id: documentTemplateId,
          },
          true
        ).unwrap();
      },
      [triggerDocumentTemplate]
    );

    const { getDocumentLayoutString } = useGetDocumentLayout();

    const getCompanyDocumentPdfLayout = useCallback(
      async ({
        documentTemplate,
        document,
        ...restProps
      }: IGetLayoutProps): Promise<ReactElement> => {
        const project = document?.project
          ? await getProject(document?.project)
          : undefined;
        const mission = document?.mission
          ? await getMission(document?.mission)
          : undefined;
        const company = await getCompany(document?.company);

        const documentTemplateObj = await getDocumentTemplate(
          documentTemplate,
          document?.template.id
        );

        // if documentTemplate is not found, throw an error
        if (!documentTemplateObj) {
          throw new Error('Document template not found');
        }

        const htmlWithMarkers = !!document?.fixedBody
          ? document.fixedBody
          : await getDocumentLayoutString({
              documentTemplate: documentTemplateObj,
              documentArgs: document,
              project: project,
              mission: mission,
              company,
              ...restProps,
            });

        return (
          <HtmlDocumentPdf
            body={htmlWithMarkers}
            backgroundImage={{ src: documentTemplateObj.file ?? '' }}
          />
        );
      },
      [
        getDocumentLayoutString,
        getCompany,
        getDocumentTemplate,
        getMission,
        getProject,
      ]
    );

    return { getCompanyDocumentPdfLayout };
  };

export default useGetCompanyDocumentPdfLayout;
