import { useTranslation } from 'react-i18next';

import type { TCurrencySymbol } from 'services/currency/currency.types';
import CurrencyService from 'services/currency/currency.service';
import { useGetSystemSettings } from 'hooks/useGetSystemSettings';

export interface ICurrencyFormatOptions {
  displayCurrency?: boolean;
  divideBy?: number;
}

interface IUseCurrency {
  symbol: TCurrencySymbol;
  divideToCents: number;
  format: (value: number, options?: ICurrencyFormatOptions) => string;
  convertToBackend: (currency: string | number | undefined) => number | null;
  convertToFrontend: (
    currency: string | number | undefined
  ) => number | undefined;
}

export const useCurrency = (): IUseCurrency => {
  const { currency } = useGetSystemSettings();
  const { i18n } = useTranslation();

  const divideToCents = 100;

  const symbol = CurrencyService.getCurrencySymbol(currency);

  const format = (value: number, options?: ICurrencyFormatOptions): string => {
    const formatter = Intl.NumberFormat(`en-${i18n.language}`, {
      style: 'currency',
      currency: 'EUR',
    });

    return `${formatter
      .format(options?.divideBy ? value / options?.divideBy : value)
      .replace('€', '')}${options?.displayCurrency ? symbol : ''}`;
  };

  const convertToBackend = CurrencyService.convertToBackend;

  const convertToFrontend = CurrencyService.convertToFrontend;

  return {
    symbol,
    divideToCents,
    format,
    convertToBackend,
    convertToFrontend,
  };
};
