import { useEffect, useState } from 'react';

import type { TThumbnailSize } from 'helpers/file/file.helpers.types';
import {
  getImageUrls,
  type TSourceValue,
} from 'helpers/file/getImageUrl.helper';

interface IUseGetImageUrlProps<T> {
  images?: string | string[];
  imageType?: TSourceValue;
  additionalRefreshTrigger?: T;
  thumbnailSize?: TThumbnailSize;
  skip?: boolean;
}

interface IUseGetImageUrlReturn {
  imageUrls: string | string[];
  isLoading: boolean;
}

const useGetImageUrl = <T>({
  images,
  imageType,
  additionalRefreshTrigger,
  thumbnailSize,
  skip = false,
}: IUseGetImageUrlProps<T>): IUseGetImageUrlReturn => {
  const [imageUrls, setImageUrls] = useState<string | string[]>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isSubscribed = true;
    const fetchImages = async () => {
      if (skip) {
        return Promise.resolve();
      }
      setIsLoading(true);
      try {
        const urls = await getImageUrls(
          images,
          imageType || 'file',
          thumbnailSize
        );
        if (isSubscribed) {
          setImageUrls(urls);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();

    return () => {
      isSubscribed = false;
      setIsLoading(false);
    };
  }, [images, imageType, additionalRefreshTrigger, thumbnailSize, skip]);

  return { imageUrls, isLoading };
};

export default useGetImageUrl;
