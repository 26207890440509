import type { TRootState } from 'store';
import type { ITableSelector } from 'interfaces/Table/selector.interface';
import { createTableSelectorSlice } from 'redux/table/helper/createTableSelector.slice';
import {
  itemsPerPageDefault,
  currentPageDefault,
  createdAtSortingDefault,
} from 'redux/table/consts/tableDefaults.consts';

export const missionCompanyDocumentsSlice = createTableSelectorSlice(
  'paginatedMissionCompanyDocuments',
  currentPageDefault,
  itemsPerPageDefault,
  createdAtSortingDefault
);

const { actions, reducer } = missionCompanyDocumentsSlice;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
  setInitialFiltersSet,
  setMenuColumnList,
} = actions;

export const paginatedMissionCompanyDocumentsSelector = (
  state: TRootState
): ITableSelector => state.tableMissionCompanyDocuments;

export default reducer;
