import type {
  IProjectMissionEmbed,
  IProjectMissionMiniEmbed,
} from 'interfaces/ProjectMission.interface';
import {
  checkEmbedIsMission,
  checkEmbedIsMissionMini,
} from 'helpers/embed/embedTypeCheck.helper';

export const getMissionIdFromIriOrEmbed = (
  mission?: IProjectMissionEmbed | IProjectMissionMiniEmbed | string
): number | undefined => {
  if (!mission) {
    return undefined;
  }
  if (checkEmbedIsMission(mission)) {
    return mission.id;
  }

  if (checkEmbedIsMissionMini(mission)) {
    const miniId = parseInt(
      mission['@id'].replace('/api/project_missions/', ''),
      10
    );
    return miniId;
  }

  const id = parseInt(mission.replace('/api/project_missions/', ''), 10);
  if (isNaN(id)) {
    return undefined;
  }
  return id;
};

export const getMissionIriFromIriOrEmbed = (
  mission: IProjectMissionEmbed | IProjectMissionMiniEmbed | string
): string => {
  if (checkEmbedIsMission(mission)) {
    return mission['@id'];
  }

  if (checkEmbedIsMissionMini(mission)) {
    return mission['@id'];
  }

  return mission;
};
