import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { IDocumentFormState } from './ProjectMissionDocumentsModal.types';
import type { IContactDocument } from 'interfaces/ContactDocument.interface';
import type { IApplicationDocument } from 'interfaces/ApplicationDocument.interface';
import type { IContactDocumentFile } from 'services/contactDocumentFiles/contactDocumentFiles.api.types';
import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import type { ICompanyDocumentFileEntry } from 'services/companyDocumentsFiles/companyDocumentFiles.api.types';
import useFileUploads, {
  type TFileUploadType,
} from 'hooks/useFileUploads.hook';
import { store } from 'store';
import { teamHeroApi } from 'services/teamHeroApi.service';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import useNotification from 'hooks/useNotification.hook';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';
import { useToggle } from 'hooks/useToggle.hook';

interface IUpdateDocumentHandlerProps {
  state: IDocumentFormState;
}

interface IUseUpdateDocumentReturn {
  saveDataStrategy: (state: IDocumentFormState) => Promise<void>;
  isProcessing: boolean;
}

interface IUseUpdateDocumentProps {
  document: ICompanyDocument | IContactDocument | IApplicationDocument | null;
  afterSaved: () => void;
  documentType?: TFileUploadType;
  fileEntity?: IContactDocumentFile | ICompanyDocumentFileEntry;
}

export const useUpdateDocument = ({
  document,
  documentType = 'contact',
  afterSaved,
  fileEntity,
}: IUseUpdateDocumentProps): IUseUpdateDocumentReturn => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();
  const { set: setNotification } = useNotification();
  const { handleError } = useErrorHandler();
  const {
    toggleValue: isProcessing,
    on: setIsProcessingOn,
    off: setIsProcessingOff,
  } = useToggle(false);
  const {
    updateDocument,
    tagToInvalidate,
    deleteDocument,
    createFile,
    createFileEntity,
    iriPrefix,
  } = useFileUploads(documentType);

  const createFileHandler = useCallback(
    async (id: number, body: FormData) => {
      try {
        await createFile({ id, body }).unwrap();
      } catch (error) {
        handleError(error, t('errors:action.uploadFileError'));
      }
    },
    [createFile, handleError, t]
  );

  const updateDocumentHandler = useCallback(
    async ({ state }: IUpdateDocumentHandlerProps): Promise<void> => {
      if (document) {
        try {
          const {
            '@type': _type,
            '@id': _atId,
            createdAt,
            createdBy,
            approvedBy,
            approvedAt,
            start,
            end,
            ...restProps
          } = document;
          await updateDocument({
            id: document?.id,
            body: {
              ...restProps,
              contact: undefined,
              template: document?.template['@id'],
              start: state.startDate
                ? formatDate(state.startDate, 'key')
                : undefined,
              end: state.endDate ? formatDate(state.endDate, 'key') : undefined,
            },
          }).unwrap();
          setNotification({
            type: 'success',
            message: t('general:work.notifications.document.updated'),
          });
          afterSaved();
        } catch (error) {
          handleError(error, t('errors:action.updateContactDocument'));
        } finally {
          store.dispatch(teamHeroApi.util.invalidateTags([tagToInvalidate]));
        }
      }
    },
    [
      t,
      afterSaved,
      document,
      formatDate,
      handleError,
      setNotification,
      tagToInvalidate,
      updateDocument,
    ]
  );

  const saveDataStrategy = useCallback(
    async (state: IDocumentFormState): Promise<void> => {
      setIsProcessingOn();
      // if there is no file in formState
      if (!state.file[0]) {
        // but there is a fileEntity set, delete the document
        if (fileEntity) {
          try {
            await deleteDocument({
              id: fileEntity.id,
            }).unwrap();
          } catch (error) {
            handleError(error, t('errors:action.deleteFileError'));
          }
          await updateDocumentHandler({ state });
        } else {
          await updateDocumentHandler({ state });
        }
      } else {
        // if there is a file in formState
        const body = new FormData();
        body.append('file', state.file[0]);
        // if there is no fileEntity set, create a new one
        if (!fileEntity) {
          try {
            const response = await createFileEntity({
              signatureReportFor: null,
              fileFor: `${iriPrefix}${document?.id}`,
            }).unwrap();
            await createFileHandler(response.id, body);
            await updateDocumentHandler({ state });
          } catch (error) {
            handleError(error, t('errors:action.uploadFileError'));
          }
        } else {
          await createFileHandler(fileEntity.id, body);
          await updateDocumentHandler({ state });
        }
      }
      setIsProcessingOff();
    },
    [
      createFileEntity,
      createFileHandler,
      deleteDocument,
      document?.id,
      fileEntity,
      handleError,
      iriPrefix,
      setIsProcessingOff,
      setIsProcessingOn,
      t,
      updateDocumentHandler,
    ]
  );

  return {
    saveDataStrategy,
    isProcessing,
  };
};
