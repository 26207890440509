import type { IContact } from 'interfaces/Contact.interface';
import type {
  IProjectMission,
  IProjectMissionEmbed,
} from 'interfaces/ProjectMission.interface';

export const getFullAddress = (
  addressDataEntity?: IProjectMission | IProjectMissionEmbed | IContact
): string => {
  if (!addressDataEntity) {
    return '';
  }
  let fullAddressString = '';

  if (addressDataEntity.addressStreet) {
    fullAddressString += addressDataEntity.addressStreet;
  }

  if (
    addressDataEntity.addressStreet &&
    (addressDataEntity.addressCity || addressDataEntity.addressZip)
  ) {
    fullAddressString += ', ';
  }

  if (addressDataEntity.addressZip) {
    fullAddressString += addressDataEntity.addressZip + ' ';
  }

  if (addressDataEntity.addressCity) {
    fullAddressString += addressDataEntity.addressCity;
  }

  return fullAddressString;
};

export const hasAddressData = (
  addressDataEntity: IProjectMission | IContact
): boolean => {
  return (
    !!addressDataEntity.addressStreet ||
    !!addressDataEntity.addressCity ||
    !!addressDataEntity.addressZip
  );
};
