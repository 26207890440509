import type { TUserRole } from 'interfaces/Role.interface';

export type TUserSnapRole =
  | 'ANONYMOUS'
  | 'COCKPIT'
  | 'HERO'
  | 'CLIENT'
  | 'APPLICANT';

// role to attach to usersnap for filtering
export const getUserSnapRoleFromUserRolesArray = (
  roles: TUserRole[]
): TUserSnapRole => {
  if (roles.includes('ROLE_HERO_LOGIN')) {
    return 'HERO';
  }

  if (roles.includes('ROLE_APPLICANT')) {
    return 'APPLICANT';
  }

  if (roles.includes('ROLE_CLIENT_LOGIN')) {
    return 'CLIENT';
  }

  if (roles.includes('ROLE_COCKPIT_LOGIN')) {
    return 'COCKPIT';
  }

  return 'ANONYMOUS';
};
