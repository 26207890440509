import type {
  IProjectEmbed,
  IProjectMiniEmbed,
} from 'interfaces/Project.interface';
import {
  checkEmbedIsProject,
  checkEmbedIsProjectMini,
} from 'helpers/embed/embedTypeCheck.helper';

export const getProjectIdFromIriOrEmbed = (
  project?: IProjectEmbed | IProjectMiniEmbed | string
): number | undefined => {
  if (!project) {
    return undefined;
  }
  if (checkEmbedIsProject(project)) {
    return project.id;
  }

  if (checkEmbedIsProjectMini(project)) {
    const miniId = parseInt(project['@id'].replace('/api/projects/', ''), 10);
    if (isNaN(miniId)) {
      return undefined;
    }
    return miniId;
  }

  const id = parseInt(project.replace('/api/projects/', ''), 10);
  if (isNaN(id)) {
    return undefined;
  }
  return id;
};

export const getProjectIriFromIriOrEmbed = (
  project: IProjectEmbed | IProjectMiniEmbed | string
): string => {
  if (checkEmbedIsProject(project)) {
    return project['@id'];
  }
  if (checkEmbedIsProjectMini(project)) {
    return project['@id'];
  }
  return project;
};
