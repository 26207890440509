import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Alert,
  Box,
  Button,
  Grid,
  Icons,
  Loader,
  Typography,
  UploadFile,
} from 'team-hero-ui';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import type { ICompanyDocumentFileEntry } from 'services/companyDocumentsFiles/companyDocumentFiles.api.types';
import type { IDocumentFormState } from './ClientLoginDocumentModal.types';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import useNotification from 'hooks/useNotification.hook';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';
import { useFileMimeType } from 'hooks/useFileMimeType.hook';
import { useToggle } from 'hooks/useToggle.hook';
import { useUpdateDocument } from 'pages/Work/ProjectMissionDetails/ProjectMissionAcceptedShifts/ProjectMissionModal/Modals/ProjectMissionDocumentsModal/useUpdateDocument.hook';
import { createBlobFile } from 'helpers/file/getFile.helper';
import { useDocumentStatus } from 'hooks/useDocumentStatus.hooks';
import useFileUploads from 'hooks/useFileUploads.hook';
import useGetFile from 'hooks/useGetFile';
import {
  MarkdownWrapperStyled,
  ModalActionsWrapper,
  ModalContentWrapper,
  ModalLoadingWrapper,
  UploadInformationBoxStyled,
  UploadInformationTextStyled,
} from 'pages/Work/ProjectMissionDetails/ProjectMissionAcceptedShifts/ProjectMissionModal/ProjectMissionModal.styled';
import ModalValidationWrapper from 'components/Modals/AddEditModal/ModalValidationWrapper';
import LocalizedDatePicker from 'components/LocalizedDatePicker';
import { addEditDocumentValidationSchema } from './Document.validation';
import CompanyDocumentPdfButton from 'components/PdfDocuments/renderers/CompanyDocument/CompanyDocumentPdfButton';

interface IClientLoginDocumentModalProps {
  document?: ICompanyDocument;
  fileEntity?: ICompanyDocumentFileEntry;
  isLoading?: boolean;
}

export const ClientLoginDocumentModal: FC<IClientLoginDocumentModalProps> = ({
  document = null,
  fileEntity,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  const { formatDate } = useDateFormat();
  const { set: setNotification } = useNotification();
  const { handleError } = useErrorHandler();
  const { acceptDocumentFiles, documentMimeTypeValidator } = useFileMimeType();
  const {
    toggleValue: isFetchingFile,
    on: setIsFetchingFileOn,
    off: setIsFetchingFileOff,
  } = useToggle(false);

  const { saveDataStrategy, isProcessing } = useUpdateDocument({
    document,
    documentType: 'company',
    afterSaved: () => {},
    fileEntity,
  });

  const { control, handleSubmit, reset, setValue } =
    useForm<IDocumentFormState>({
      defaultValues: {
        startDate: document?.start ? moment(document.start) : null,
        endDate: document?.end ? moment(document.end) : null,
        file: [],
      },
      resolver: joiResolver(
        addEditDocumentValidationSchema(documentMimeTypeValidator(false)),
        {
          allowUnknown: true,
        }
      ),
    });

  useEffect(() => {
    reset();
  }, [document, reset]);

  const { type, statusLabel } = useDocumentStatus(document);

  const { filePrefix } = useFileUploads('company');

  const { getFile } = useGetFile(
    fileEntity?.originalFileName,
    fileEntity?.file
  );

  const handleSave = async (state: IDocumentFormState) => {
    await saveDataStrategy(state);
    reset();
  };

  useEffect(() => {
    if (fileEntity?.file) {
      setIsFetchingFileOn();
      createBlobFile(`${filePrefix}${fileEntity?.id}/file`)
        .then((fileUrl) => {
          setValue('file', [
            new File([fileUrl], fileEntity.originalFileName || ''),
          ]);
        })
        .catch((error) => {
          handleError(error, t('action.downloadFileError'));
          setValue('file', []);
        })
        .finally(() => {
          setIsFetchingFileOff();
        });
    }

    return () => {
      setValue('file', []);
      setIsFetchingFileOff();
    };
  }, [
    fileEntity,
    filePrefix,
    handleError,
    setIsFetchingFileOff,
    setIsFetchingFileOn,
    setNotification,
    setValue,
    t,
  ]);

  const canEditStartDate =
    document?.template.type === 'uploadable' && !document?.start;
  const canEditEndDate =
    document?.template.type === 'uploadable' && !document?.end;

  const fileName = [
    document?.template.title || '',
    formatDate(moment(), 'dateNoBreaks'),
  ]
    .filter((value) => !!value)
    .join('-');

  return (
    <>
      <ModalContentWrapper>
        {isLoading ? (
          <ModalLoadingWrapper>
            <Loader loaderSize='small' loaderType='static' />
          </ModalLoadingWrapper>
        ) : (
          <>
            {(isProcessing || isFetchingFile) && (
              <ModalLoadingWrapper>
                <Loader loaderSize='small' loaderType='static' />
              </ModalLoadingWrapper>
            )}
            <Box mb={4}>
              <Typography.H2>{document?.template.title}</Typography.H2>
            </Box>
            <Box
              mb={4}
              display='flex'
              alignItems='flex-start'
              flexDirection={'column'}
            >
              <Box mb={1}>
                <Typography.H4>
                  {t('work.modals.documents.labels.status')}
                </Typography.H4>
              </Box>
              {type ? <Alert type={type}>{statusLabel}</Alert> : <span>-</span>}
            </Box>
            <Box width={1} mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {canEditStartDate ? (
                    <Controller
                      key={'startDate'}
                      control={control}
                      name={'startDate'}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <ModalValidationWrapper text={error?.message}>
                          <LocalizedDatePicker
                            label={t('work.modals.documents.labels.startDate')}
                            value={value}
                            onChange={onChange}
                            type='dateModal'
                          />
                        </ModalValidationWrapper>
                      )}
                    />
                  ) : (
                    <>
                      <Box mb={1}>
                        <Typography.H4>
                          {t('work.modals.documents.labels.startDate')}
                        </Typography.H4>
                      </Box>
                      <Box>
                        {document?.start && (
                          <small>{formatDate(document.start)}</small>
                        )}
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {canEditEndDate ? (
                    <Controller
                      key={'endDate'}
                      control={control}
                      name={'endDate'}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <ModalValidationWrapper text={error?.message}>
                          <LocalizedDatePicker
                            label={t('work.modals.documents.labels.endDate')}
                            value={value}
                            onChange={onChange}
                            type='dateModal'
                          />
                        </ModalValidationWrapper>
                      )}
                    />
                  ) : (
                    <>
                      <Box mb={1}>
                        <Typography.H4>
                          {t('work.modals.documents.labels.endDate')}
                        </Typography.H4>
                      </Box>
                      <Box>
                        {document?.end && (
                          <small>{formatDate(document.end)}</small>
                        )}
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box mb={4} width='min-content'>
              {document && document?.template.type !== 'uploadable' && (
                <CompanyDocumentPdfButton
                  document={document}
                  fileName={fileName}
                />
              )}
            </Box>
            <Box width={1} mb={5}>
              <Box mb={1}>
                <Typography.H4>
                  {t('work.modals.documents.labels.signature')}
                </Typography.H4>
              </Box>
              {!fileEntity?.file && (
                <UploadInformationBoxStyled>
                  <Icons.ExclamationMarkIcon svgSize={20} />
                  <UploadInformationTextStyled>
                    {t('work.modals.uploadDocument')}
                  </UploadInformationTextStyled>
                </UploadInformationBoxStyled>
              )}
              <Controller
                key={'file'}
                name={'file'}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <ModalValidationWrapper text={error?.message}>
                    <UploadFile
                      label={t('file.add')}
                      elementListLabel={t('modal.labels.filename')}
                      value={value}
                      onChange={onChange}
                      maxItems={1}
                      onDownloadFile={getFile}
                      downloadAction
                      disabled={isFetchingFile}
                      deleteAction={false}
                      accept={acceptDocumentFiles}
                      editAction={!fileEntity}
                    />
                  </ModalValidationWrapper>
                )}
              />
            </Box>
            {document?.template?.description && (
              <Box width={1}>
                <Box mb={1}>
                  <Typography.H4>
                    {t('work.modals.documents.labels.description')}
                  </Typography.H4>
                </Box>
                <MarkdownWrapperStyled>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={document?.template?.description || ''}
                  />
                </MarkdownWrapperStyled>
              </Box>
            )}
          </>
        )}
      </ModalContentWrapper>
      <ModalActionsWrapper>
        <Button
          color='green'
          disabled={
            !!fileEntity?.file || isProcessing || isFetchingFile || isLoading
          }
          onClick={() => {
            handleSubmit(handleSave)();
          }}
        >
          {t('work.modals.buttons.saveDocument')}
        </Button>
      </ModalActionsWrapper>
    </>
  );
};
