import { styled } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ModalWrapper = styled.div`
  width: 100vw;
  height: calc(100dvh - ${pxToRem(45)});

  @media ${device.md} {
    width: 70vw;
  }
  @media ${device.lg} {
    width: ${pxToRem(460)};
  }
`;

export const ModalLoadingWrapper = styled.div`
  width: 100%;
  height: calc(100dvh - ${pxToRem(65)});
  ${flexFn('center', 'center')};
  position: absolute;
  background: ${Theme.colors.primary.grey};
  z-index: 2;
  left: 0;
  top: 0;
`;

export const ModalContentWrapper = styled.div`
  height: calc(100dvh - ${pxToRem(110)});
  width: 100%;
  min-width: ${pxToRem(320)};
  overflow-y: auto;
  padding: ${pxToRem(24)};
`;

export const ModalActionsWrapper = styled.div`
  height: ${pxToRem(64)};
  width: 100%;
  padding: ${pxToRem([0, 24])};
  ${flexFn('space-between', 'center')};
  box-shadow: 0 -0.1875rem 0.375rem 0 rgb(0 0 0 / 16%);
`;

export const ValidationErrorMessage = styled.p`
  color: ${Theme.colors.primary.red};
  font-size: ${pxToRem(12)};
  margin-top: ${pxToRem(3)};
`;

export const UploadInformationBoxStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
  padding: ${pxToRem([16, 24])};
  background-color: ${Theme.colors.primary.red}20;
  border: ${pxToRem(2)} dotted ${Theme.colors.primary.red};
  margin-bottom: ${pxToRem(12)};
`;

export const UploadInformationTextStyled = styled.div`
  font-size: ${pxToRem(13)};
`;

export const CurrencyInputStyled = styled.div`
  ${flexFn()};
`;

export const MarkdownWrapperStyled = styled.div`
  & a {
    color: inherit;
  }
`;
