import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from 'team-hero-ui';
import { Moment } from 'moment';

import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IProjectCostsWidget } from 'interfaces/Widget/Widget.interface';
import type { TWidgetResult } from 'services/widgets/widgets.types';
import type { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';
import { useGetWidgetItemQuery } from 'services/teamHeroApi.service';
import { dateService } from 'services/date/date.service';
import { TimetrackingsBottomHeaderColumnsFactory } from '../components/SummaryRow/TimetrackingsBottomHeaderColumns.config';
import { SummaryRow } from 'components/Table/components/SummaryRow/SummaryRow';

interface IUseTimetrackingsEntrySummaryRowProps {
  hidden: boolean;
  startDate: Moment;
  endDate: Moment;
  missionId: number;
  projectId?: number;
  timetrackings: IProjectTimesheet[];
  enableClientContacts: boolean;
}

interface IUseTimetracingsSummaryRowReturn {
  summaryBottomRow: JSX.Element | null;
}

export const useTimetrackingsSummaryRow = ({
  hidden,
  projectId,
  missionId,
  startDate,
  endDate,
  timetrackings,
  enableClientContacts,
}: IUseTimetrackingsEntrySummaryRowProps): IUseTimetracingsSummaryRowReturn => {
  const { t } = useTranslation('general');
  const widgetsFilter: ISimpleFilter[] = [];

  if (projectId) {
    widgetsFilter.push(
      ...[projectId].map(
        (id) =>
          ({
            key: 'project_ids',
            operator: 'OR',
            value: id,
          }) as ISimpleFilter
      )
    );
  }

  if (startDate) {
    widgetsFilter.push({
      key: 'start_date',
      operator: 'AND',
      value: dateService(startDate).format('YYYY-MM-DD'),
    });
  }

  if (endDate) {
    widgetsFilter.push({
      key: 'end_date',
      operator: 'AND',
      value: dateService(endDate).format('YYYY-MM-DD'),
    });
  }

  const { data: summaryData } = useGetWidgetItemQuery<
    TWidgetResult<IProjectCostsWidget>
  >(
    {
      key: 'projects-costs',
      filters: [...widgetsFilter],
    },
    { skip: !projectId }
  );

  const projectCostsSummaryData = summaryData?.data?.projects;

  const summaryProjectDataAsArray = projectCostsSummaryData
    ? Object.keys(projectCostsSummaryData).map(
        (key) => projectCostsSummaryData[key]
      )
    : [];

  const summaryProject = summaryProjectDataAsArray.find(
    (item) => item.id === projectId
  );

  const summaryMission = summaryProject?.missions?.find(
    (mission) => mission.id === missionId
  );

  const summaryBottomRow = useMemo(() => {
    if (!summaryProject || hidden || !projectId) {
      return null;
    }

    const filteredEntriesHours = timetrackings.reduce(
      (acc, item) => acc + (item.durationHours || 0),
      0
    );

    const filteredEntriesHoursNoBreaks = timetrackings.reduce((acc, item) => {
      if (item.isBreak) {
        return acc;
      }
      return acc + (item.durationHours || 0);
    }, 0);

    return (
      <>
        <SummaryRow
          columns={TimetrackingsBottomHeaderColumnsFactory({
            t,
            enableClientContacts,
            hours: filteredEntriesHours,
            barDescription: t('table.header.labels.filteredEntries'),
            mainBarBgColor: Theme.colors.greyShades.grey3,
            bold: true,
          })}
          row={summaryProject}
        />
        <SummaryRow
          columns={TimetrackingsBottomHeaderColumnsFactory({
            t,
            enableClientContacts,
            hours: filteredEntriesHoursNoBreaks,
            barDescription: t(
              'table.header.labels.filteredEntriesWithoutBreaks'
            ),
            mainBarBgColor: Theme.colors.greyShades.grey3,
          })}
          row={summaryProject}
        />
        <SummaryRow
          columns={TimetrackingsBottomHeaderColumnsFactory({
            t,
            enableClientContacts,
            hours: summaryMission?.tracked_hours || 0,
            barDescription: t('table.header.labels.totalMission'),
            mainBarBgColor: Theme.colors.greyShades.grey2,
            bold: true,
          })}
          row={summaryProject}
        />
      </>
    );
  }, [
    summaryProject,
    hidden,
    projectId,
    timetrackings,
    t,
    enableClientContacts,
    summaryMission?.tracked_hours,
  ]);

  return { summaryBottomRow };
};
