import { ReactElement, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import type { IDocumentTemplate } from 'interfaces/DocumentTemplate.interface';
import type { ICompanyDocument } from 'interfaces/CompanyDocument.interface';
import { getCompanyIdFromIriOrCompany } from 'helpers/company/getCompanyId.helper';
import useSavePdfFile from 'hooks/useSavePdfFile.hook';
import { useCurrency } from 'hooks/numbers/useCurrency.hook';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { useLazyGetCompanyItemQuery } from 'services/teamHeroApi.service';
import useGetCompanyDocumentPdfLayout from './useGetCompanyDocumentPdfLayout.hook';

interface IUseSaveCompanyPdfDocumentReturn {
  getCompanyPdfLayout: (
    document?: ICompanyDocument,
    documentTemplate?: IDocumentTemplate
  ) => Promise<ReactElement>;
  saveCompanyPdfFile: (
    document: ICompanyDocument,
    documentTemplate?: IDocumentTemplate
  ) => void;
  isLoading: boolean;
}

export const useCompanyPdfDocument = (): IUseSaveCompanyPdfDocumentReturn => {
  const { t } = useTranslation('general');
  const { getCompanyDocumentPdfLayout } = useGetCompanyDocumentPdfLayout();

  const { format } = useCurrency();
  const { savePdfFile, isLoading } = useSavePdfFile();
  const { formatDate } = useDateFormat();

  const [triggerCompany] = useLazyGetCompanyItemQuery();

  const getCompany = useCallback(
    async (companyId?: number) => {
      if (!companyId) return undefined;
      return triggerCompany(
        {
          id: companyId,
        },
        true
      ).unwrap();
    },
    [triggerCompany]
  );

  const getCompanyPdfLayout = useCallback(
    (document?: ICompanyDocument, documentTemplate?: IDocumentTemplate) =>
      getCompanyDocumentPdfLayout({
        document,
        documentTemplate,
        currencyFormatter: format,
        t,
      }),
    [format, getCompanyDocumentPdfLayout, t]
  );

  const saveCompanyPdfFile = async (
    document: ICompanyDocument,
    documentTemplate?: IDocumentTemplate
  ) => {
    const company = await getCompany(
      getCompanyIdFromIriOrCompany(document.company)
    );

    const fileName = [
      document.template.title,
      company?.name,
      company?.id,
      formatDate(moment(), 'dateNoBreaks'),
    ]
      .filter((value) => !!value)
      .join('-');

    await savePdfFile({
      fileName,
      getPdfLayout: () => getCompanyPdfLayout(document, documentTemplate),
    });
  };

  return {
    getCompanyPdfLayout,
    saveCompanyPdfFile,
    isLoading,
  };
};
